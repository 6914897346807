import React from 'react';
import { Assessment, Scenario } from 'src/api/types';
import { Poppins, Spacer } from 'src/common';
import _ from 'lodash';
import { useColumns } from './useColumns';
import GenericTable from 'src/components/GenericTable';
import { TableIds } from 'src/api/types/misc';

interface AssessmentViewProps {
  data: Assessment;
  genPath: (a: Scenario) => string | undefined;
}

export const AssessmentView: React.FC<AssessmentViewProps> = ({ data, genPath }) => {
  const { columns, GTColumnSelectAnchorExported } = useColumns({
    data: data?.scenarios || [],
    genPath,
  });

  return (
    <>
      <Poppins weight={600} px={22} color="prussianBlue">
        {data.name}
      </Poppins>
      <Spacer $px={15} />
      <div>
        <Poppins weight={600} px={22} color="prussianBlue">
          {data.scenarios.length}
        </Poppins>
        <Poppins px={18} className="scenario-label" color="cflowerBlue" css="margin-left: 5px;">
          scenario{data.scenarios.length ? 's' : ''}
        </Poppins>
      </div>
      <Spacer $px={15} />
      <GenericTable
        tableId={TableIds.compareAssessments}
        data={data.scenarios}
        columns={columns}
        GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
      />
    </>
  );
};
