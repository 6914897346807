import React from 'react';
import styled from 'styled-components';
import { Avatar } from '@mui/material';
import colors from 'src/theme/colors';

const StyledAvatar = styled(Avatar)`
  background: ${colors.gradient};
  width: 21px;
  height: 21px;
  font-size: 14px;
`;

const AvatarWrapper = styled.div<{ $cursorPointer?: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: 10px;
  grid-gap: 10px;
  &:hover {
    cursor: ${({ $cursorPointer }) => ($cursorPointer ? 'pointer' : 'default')};
    .table-link {
      color: ${colors.red};
    }
  }
`;

interface TableUserItemProps {
  onClick?: () => void;
  profilePhotoPath?: string | null;
  name: string;
}

export const TableUserItem: React.FC<TableUserItemProps> = ({ onClick, profilePhotoPath, name }) => {
  return (
    <AvatarWrapper onClick={onClick} $cursorPointer={!!onClick}>
      <StyledAvatar src={profilePhotoPath || ''} />
      <span className={`elipsis ${!!onClick ? 'table-link' : ''}`}>{name}</span>
    </AvatarWrapper>
  );
};
