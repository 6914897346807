// @ts-nocheck
import React, { memo } from 'react';
import { pdfStyles } from '../../pdfStyles';
import { Page, Text, View } from '@react-pdf/renderer';
import { Footer, HeaderCompany } from '../../comps';
import { isEqual } from 'lodash';
import { PdfAssessment, User } from 'src/api/types';
import { formatDate } from '../../util';

interface AssessmentHeadProps {
  assessment: PdfAssessment;
  user: User | null;
}

export const AssessmentHead: React.FC<AssessmentHeadProps> = memo(
  ({ assessment, user }) => {
    return (
      // @ts-ignore
      <Page size={'A4'} wrap style={pdfStyles.page}>
        <HeaderCompany
          companyName={user?.workspace_name || ''}
          title={assessment.name}
          companyImage={''}
          label="RISK ASSESSMENT"
        />

        {/* @ts-ignore */}
        <View style={{ ...pdfStyles.section, flex: 1, justifyContent: 'flex-end' }}>
          <Text style={pdfStyles.gradText}>DESCRIPTION</Text>
          <View style={pdfStyles.spacer1} />
          <Text style={pdfStyles.p}>{assessment.description || '-'}</Text>
          <View style={pdfStyles.spacer16} />
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: 120 }}>
              <Text style={pdfStyles.gradText}>REPORT DATE</Text>
              <View style={pdfStyles.spacer1} />
              <Text style={pdfStyles.p}>{formatDate(new Date())}</Text>
            </View>
            <View style={{ width: 120 }}>
              <Text style={pdfStyles.gradText}>START DATE</Text>
              <View style={pdfStyles.spacer1} />
              <Text style={pdfStyles.p}>{formatDate(assessment.started_date)}</Text>
            </View>
            <View style={{ width: 120 }}>
              <Text style={pdfStyles.gradText}>UPDATED</Text>
              <View style={pdfStyles.spacer1} />
              <Text style={pdfStyles.p}>{formatDate(assessment.meta?.updated_at)}</Text>
            </View>
            <View>
              <Text style={pdfStyles.gradText}>EXPORTED BY</Text>
              <View style={pdfStyles.spacer1} />
              <Text style={pdfStyles.p}>{user?.name}</Text>
            </View>
          </View>
        </View>

        <Footer />
      </Page>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
