import React from 'react';
import { Poppins, Spacer } from 'src/common';
import styled from 'styled-components';
import Input from 'src/components/form/Input';
import colors from 'src/theme/colors';
import { LogSwitch } from 'src/components/charts/comps';

const Div = styled.div`
  .panel {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 20px;

    align-items: center;

    &__title {
      font-weight: 600;
      color: ${colors.prussianBlue};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__controlls {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      grid-gap: 60px;

      .blocks {
        display: flex;
        flex-direction: column;
        padding: 0 !important;

        &__title {
          font-weight: 500;
          color: ${colors.cflowerBlue};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &__inputs {
          grid-gap: 10px;
          align-items: center;
          display: flex;
          justify-content: flex-end;
          &__title {
            font-weight: 500;
            color: ${colors.prussianBlue};
          }
          &__item {
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            grid-gap: 14px;
          }
        }
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    .panel {
      grid-template-columns: 1fr;
      &__controlls {
        justify-content: flex-start;
        grid-gap: 20px;
      }
    }
  }
`;

interface ControllsPanelProps {
  nameA?: string;
  nameB?: string;
  scenarioNameA?: string;
  scenarioNameB?: string;
  seriesAEnabled: boolean;
  seriesBEnabled: boolean;
  seriesARiskEnabled?: boolean;
  seriesBRiskEnabled?: boolean;
  isLogarithmic: boolean;
  setSeriesAEnabled: (val: boolean) => void;
  setSeriesBEnabled: (val: boolean) => void;
  setSeriesARiskEnabled?: (val: boolean) => void;
  setSeriesBRiskEnabled?: (val: boolean) => void;
  setIsLogarithmic: (val: boolean) => void;
  typeScenario?: boolean;
}

export const ControllsPanel: React.FC<ControllsPanelProps> = ({
  nameA = '',
  nameB = '',
  scenarioNameA = '',
  scenarioNameB = '',
  seriesAEnabled,
  seriesBEnabled,
  seriesARiskEnabled,
  seriesBRiskEnabled,
  isLogarithmic,
  setSeriesAEnabled,
  setSeriesBEnabled,
  setSeriesARiskEnabled,
  setSeriesBRiskEnabled,
  setIsLogarithmic,
  typeScenario,
}) => {
  return (
    <Div>
      <div className="panel">
        <Poppins className="panel__title" px={32}>
          {typeScenario ? nameA : `${nameA} / ${nameB}`}
        </Poppins>
        <div className="panel__controlls">
          <div className="blocks">
            <Poppins className="blocks__title" px={14}>
              {typeScenario ? scenarioNameA : nameA}
            </Poppins>
            <div className="blocks__inputs">
              <div className="blocks__inputs__item">
                <Poppins className="blocks__inputs__title" px={14}>
                  Loss Exceedance
                </Poppins>
                <Input
                  checked={seriesAEnabled}
                  onChange={(e) => setSeriesAEnabled(e.target.checked)}
                  className="switch"
                  type="checkbox"
                />
              </div>
              {!typeScenario && (
                <div className="blocks__inputs__item">
                  <Poppins className="blocks__inputs__title" px={14}>
                    Risk Tolerance
                  </Poppins>
                  <Input
                    checked={seriesARiskEnabled}
                    onChange={(e) => setSeriesARiskEnabled && setSeriesARiskEnabled(e.target.checked)}
                    className="switch"
                    type="checkbox"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="blocks">
            <Poppins className="blocks__title" px={14}>
              {typeScenario ? scenarioNameB : nameB}
            </Poppins>
            <div className="blocks__inputs">
              <div className="blocks__inputs__item">
                <Poppins className="blocks__inputs__title" px={14}>
                  Loss Exceedance
                </Poppins>
                <Input
                  checked={seriesBEnabled}
                  onChange={(e) => setSeriesBEnabled(e.target.checked)}
                  className="switch"
                  type="checkbox"
                />
              </div>
              {!typeScenario && (
                <div className="blocks__inputs__item">
                  <Poppins className="blocks__inputs__title" px={14}>
                    Risk Tolerance
                  </Poppins>
                  <Input
                    checked={seriesBRiskEnabled}
                    onChange={(e) => setSeriesBRiskEnabled && setSeriesBRiskEnabled(e.target.checked)}
                    className="switch"
                    type="checkbox"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Spacer $px={20} />
      <div className="lec">
        <Poppins className="gradient-text" px={18}>
          LOSS EXCEEDANCE CURVE
        </Poppins>
        <LogSwitch isLogarithmic={isLogarithmic} setIsLogarithmic={setIsLogarithmic} />
      </div>
    </Div>
  );
};
