import React from 'react';
import colors from 'src/theme/colors';

interface Props {
  isActive?: boolean;
}

export const IconGear: React.FC<Props> = ({ isActive }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
      <path
        d="M11.9765 7.52099C11.6643 7.28104 11.5144 6.8938 11.5144 6.5C11.5144 6.10849 11.6593 5.71813 11.9711 5.48136L12.8766 4.79375C13.002 4.69625 13.0354 4.5175 12.9602 4.37937L11.623 2.12875C11.5395 1.9825 11.364 1.93375 11.2135 1.9825L10.1413 2.40158C9.7736 2.54528 9.36392 2.48133 9.0208 2.28618C8.66403 2.08326 8.38575 1.74841 8.32663 1.34225L8.17147 0.27625C8.14639 0.11375 8.00432 0 7.83717 0H5.16283C4.99568 0 4.86196 0.11375 4.83689 0.27625L4.68192 1.34095C4.62268 1.74792 4.34023 2.08599 3.98314 2.28998C3.641 2.48544 3.23548 2.54556 2.86848 2.40212L1.79482 1.9825C1.64439 1.92562 1.46888 1.9825 1.38531 2.12875L0.0481344 4.37937C-0.035439 4.52562 -0.00200953 4.69625 0.131708 4.79375L1.03667 5.48094C1.34882 5.71797 1.4856 6.10806 1.4856 6.5C1.4856 6.8915 1.34069 7.28186 1.02889 7.51862L0.12335 8.20625C-0.00200973 8.30375 -0.035439 8.4825 0.039777 8.62062L1.37695 10.8712C1.46052 11.0175 1.63603 11.0662 1.78646 11.0175L2.85874 10.5984C3.2264 10.4547 3.63607 10.5187 3.9792 10.7138C4.33597 10.9167 4.61425 11.2516 4.67337 11.6577L4.82853 12.7237C4.86196 12.8862 4.99568 13 5.16283 13H7.83717C8.00432 13 8.14639 12.8862 8.16311 12.7237L8.31808 11.659C8.37732 11.2521 8.65976 10.914 9.01686 10.71C9.359 10.5146 9.76452 10.4544 10.1315 10.5979L11.2052 11.0175C11.3556 11.0744 11.5311 11.0175 11.6147 10.8712L12.9519 8.62062C13.0354 8.47437 13.002 8.30375 12.8683 8.20625L11.9765 7.52099ZM6.5 8.9375C5.12104 8.9375 3.9928 7.84062 3.9928 6.5C3.9928 5.15937 5.12104 4.0625 6.5 4.0625C7.87896 4.0625 9.0072 5.15937 9.0072 6.5C9.0072 7.84062 7.87896 8.9375 6.5 8.9375Z"
        fill={isActive ? 'url(#paint0_linear_7334_14785)' : colors.cflowerBlue}
      />
      <defs>
        <linearGradient id="paint0_linear_7334_14785" x1="6.5" y1="0" x2="6.5" y2="13" gradientUnits="userSpaceOnUse">
          <stop stop-color="#E62E61" />
          <stop offset="1" stop-color="#FD5630" />
        </linearGradient>
      </defs>
    </svg>
  );
};
