import moment from 'moment';
import React from 'react';
import { DateCapsule, Poppins } from 'src/common';
import styled from 'styled-components';

const Div = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 8px;
`;

interface ReviewDateProps {
  date: string;
}

export const ReviewDate: React.FC<ReviewDateProps> = ({ date }) => {
  return (
    <Div>
      <Poppins px={14} weight={500} color="davysGrey">
        Review Date
      </Poppins>
      <DateCapsule $highlighted={moment(date).isBefore(moment())}>
        <Poppins px={14} weight={600}>
          {date}
        </Poppins>
      </DateCapsule>
    </Div>
  );
};
