import React from 'react';
import { Control } from 'src/api/types';
import { Spacer } from 'src/common';
import { formatDate } from 'src/utils/misc';
import { Label, Wrap } from './styles';
import styled from 'styled-components';

const Div = styled(Wrap)``;

interface TableSheetRmpProps {
  data: Control;
}

export const TableSheetRmp: React.FC<TableSheetRmpProps> = ({ data }) => {
  return (
    <Div>
      <div className="grid grid--2">
        <div>
          <Label>STRATEGY</Label>
          <div className="value">{data.strategy}</div>
        </div>
        <div>
          <Label>NAME</Label>
          <div className="value">{data.name}</div>
        </div>
      </div>
      <Spacer $px={10} />
      <div className="grid grid--2">
        <div>
          <Label>FRAMEWORK</Label>
          <div className="value">{data.frameworkLibrary_name}</div>
        </div>
        <div>
          <Label>FRAMEWORK ID</Label>
          <div className="value">{data.controlId}</div>
        </div>
      </div>
      <Spacer $px={10} />
      <div className="grid grid--3">
        <div>
          <Label>OWNER</Label>
          <div className="value">{data.owner?.text}</div>
        </div>
        <div>
          <Label>STATUS</Label>
          <div className="value">{data.status}</div>
        </div>
        <div>
          <Label>EXPECTED LIVE DATE</Label>
          <div className="value">{formatDate(data.expected_active_date || '')}</div>
        </div>
      </div>
      <Spacer $px={10} />
      <Label>DESCRIPTION</Label>
      <div className="value">{data.description}</div>
      {/* <Spacer $px={10} />
      <div className="grid grid--2">
        <div>
          <Label>STATUS</Label>
          <div className="value">{data.status}</div>
        </div>
        <div>
          <Label>DATE STARTED</Label>
          <div className="value">{data.created_at}</div>
        </div>
      </div> */}
    </Div>
  );
};
