import React from 'react';
import styled from 'styled-components';
import { AuditAction } from 'src/api/types';
import { Poppins } from 'src/common';
import { constantsNaming } from './util';

const Div = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-top: 1px solid #e9ecff;
  border-left: 1px solid #e9ecff;
  background-color: #f6f6f9;

  .grid-block {
    display: flex;
    padding: 4px 12px;
    border-bottom: 1px solid #e9ecff;
    border-right: 1px solid #e9ecff;
    display: inline-block;
    word-break: break-word;

    .align-center {
      text-align: center;
    }
  }
`;

interface IActionsGridProps {
  actions: AuditAction[];
}

export const ActionsGrid: React.FC<IActionsGridProps> = ({ actions }) => {
  return (
    <>
      <Div>
        <div className="grid-block" />
        <div className="grid-block">
          <Poppins px={14} color="prussianBlue" weight={500} className="align-center">
            Previous
          </Poppins>
        </div>
        <div className="grid-block">
          <Poppins px={14} color="prussianBlue" weight={500} className="align-center">
            Current
          </Poppins>
        </div>
        {actions.map((el) => (
          <React.Fragment key={el.field_name}>
            <div className="grid-block">
              <Poppins px={14} color="prussianBlue" weight={500}>
                {el.field_name}
              </Poppins>
            </div>
            <div className="grid-block">
              <Poppins px={14} color="prussianBlue" weight={500}>
                {constantsNaming(el.field_name, el.before)}
              </Poppins>
            </div>
            <div className="grid-block">
              <Poppins px={14} color="prussianBlue" weight={500}>
                {constantsNaming(el.field_name, el.after)}
              </Poppins>
            </div>
          </React.Fragment>
        ))}
      </Div>
    </>
  );
};
