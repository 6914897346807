import React, { useState } from 'react';
import styled from 'styled-components';
import { FormikProvider, useFormik } from 'formik';
import { queryCache, useMutation } from 'react-query';
import * as Yup from 'yup';
import { Poppins, Spacer } from 'src/common';
import { Guard } from '../Guard';
import { Modal } from '../comps';
import TabsMenu from '../../TabsMenu';
import { FromCsv, FromLib } from './forms';
import { motion } from 'framer-motion';
import { addControllsFromLibrary, createControl } from 'src/api/controls';
import RmpForm from 'src/components/formComposed/RmpForm';
import { rmpInitialValues, rmpValidationSchema } from 'src/components/formComposed/RmpForm/util';
import { selectUserOption } from 'src/utils/misc';
import { useAuth } from 'src/state/auth';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const StyledModal = styled(Modal)`
  width: 1568px;
  min-height: 200px;
  padding: 0;
  overflow: hidden;

  .content {
    overflow-y: auto;
    max-height: calc(100vh - 40px);
  }
`;

const schemaLib = Yup.object({
  selected: Yup.array().of(Yup.string()).required('Required'),
});

interface AddRmpModalProps {
  onClose: () => void;
}

export const AddRmpModal: React.FC<AddRmpModalProps> = ({ onClose }) => {
  const [isCloseRequested, setIsCloseRequested] = useState(false);
  const [activeTab, setActiveTab] = useState<'from_new' | 'from_lib' | 'from_csv'>('from_new');
  const [responseErr, setResponseErr] = useState('');
  const { usersOptios } = useAuth();

  // csv
  const [isScvDirty, setIsScvDirty] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [readyForUpload, setReadyForUpload] = useState(false);

  const onSuccess = () => {
    queryCache.invalidateQueries();
    onClose();
  };

  const onError = (err: any) => setResponseErr(err.message || 'Server error');

  const [addFromLib] = useMutation(addControllsFromLibrary, {
    onSuccess,
    onError,
  });

  const [addFromNew] = useMutation(createControl, { onSuccess, onError });

  const formikNew = useFormik({
    initialValues: rmpInitialValues,
    validationSchema: rmpValidationSchema,
    validateOnMount: true,

    onSubmit: async (values) => {
      setResponseErr('');

      const { userAdd } = selectUserOption({
        usersOptios,
        selectedUser: values.owner,
      });

      return addFromNew({ ...values, owner: userAdd });
    },
  });

  const formikLib = useFormik({
    initialValues: {
      selected: [],
    },
    validationSchema: schemaLib,

    onSubmit: async (values) => {
      setResponseErr('');

      return addFromLib({
        controlLibraryIds: values.selected.map((el) => {
          return { id: el };
        }),
      });
    },
  });

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={() => onClose()}
      isOpen={isCloseRequested}
      isDirty={formikLib.dirty || formikNew.dirty || isScvDirty}
      modalComponent={
        <StyledModal onRequestClose={() => setIsCloseRequested(true)} isOpen center={false}>
          <div className="content">
            <Spacer $px={40} />
            <div className="h-padding">
              <Poppins className="m-title" px={28}>
                Add Control
              </Poppins>
              <Spacer $px={40} />
              <TabsMenu
                tabs={[
                  { label: 'Enter Control Details', value: 'from_new', dataCy: 'from_new' },
                  { label: 'Import From Framework', value: 'from_lib', dataCy: 'from_lib' },
                  { label: 'Import From CSV', value: 'from_csv', dataCy: 'from_csv' },
                ]}
                activeTab={activeTab}
                setActiveTab={(tab) => {
                  setActiveTab(tab);
                  mpEvent(MPEvents.ButtonClick, {
                    button:
                      tab === 'from_new'
                        ? 'Tab:Enter Control Details'
                        : tab === 'from_lib'
                          ? 'Tab:Import From Framework'
                          : 'Tab:Import From CSV',
                    modal: 'Add RMP modal',
                    tags: ['RMP'],
                  });
                }}
              />
              <Spacer $px={45} />
            </div>
            {activeTab === 'from_new' && (
              <FormikProvider value={formikNew}>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0, duration: 0.1 }}>
                  <RmpForm
                    buttonTitle="CREATE"
                    onSubmitCb={() =>
                      mpEvent(MPEvents.ButtonClick, {
                        button: 'Create',
                        modal: 'Add RMP modal',
                        tags: ['RMP'],
                      })
                    }
                  />
                </motion.div>
              </FormikProvider>
            )}
            {activeTab === 'from_lib' && (
              <FormikProvider value={formikLib}>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0, duration: 0.1 }}>
                  <FromLib />
                </motion.div>
              </FormikProvider>
            )}
            {activeTab === 'from_csv' && (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0, duration: 0.1 }}>
                <FromCsv
                  onClose={onClose}
                  setIsScvDirty={setIsScvDirty}
                  state={{ file, setFile, readyForUpload, setReadyForUpload }}
                />
              </motion.div>
            )}

            {!!responseErr && (
              <div className="h-padding">
                <Spacer $px={15} />
                <div className="error">{responseErr}</div>
              </div>
            )}
            <Spacer $px={40} />
          </div>
        </StyledModal>
      }
    />
  );
};
