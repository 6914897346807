import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, ModalSubTitle } from '../comps';
import colors from 'src/theme/colors';
import { Poppins, Spacer } from 'src/common';
import { queryCache, useMutation } from 'react-query';
import { QKeys } from 'src/api/types';
import { Guard } from '../Guard';
import _ from 'lodash';
import { createIncident, linkIncident } from 'src/api/incidents';
import TabsMenu from 'src/components/TabsMenu';
import { FromExisting } from './comps';
import { motion } from 'framer-motion';
import IncidentForm from 'src/components/formComposed/IncidentForm';
import { FormikProvider, useFormik } from 'formik';
import { incidentInitialValues, incidentValidationSchema } from 'src/components/formComposed/IncidentForm/util';
import { selectUserOption } from 'src/utils/misc';
import { useAuth } from 'src/state/auth';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const StyledModal = styled(Modal)`
  width: 1568px;
  min-height: 200px;
  padding: 0;
  max-height: calc(100% - 40px);
  overflow-y: auto;

  .content {
    overflow-y: auto;
  }

  .error {
    color: ${colors.error};
    font-size: 14px;
    text-align: center;
  }
`;

interface LinkIncidentsToScenarioModalProps {
  onClose: () => void;
  scenarioId: string;
  subTitle?: {
    title: string;
    value: string;
  };
}

export const LinkIncidentsToScenarioModal: React.FC<LinkIncidentsToScenarioModalProps> = ({
  onClose,
  scenarioId,
  subTitle,
}) => {
  const [activeTab, setActiveTab] = useState<'from_new' | 'from_existing'>('from_new');
  const [err, setErr] = useState('');
  const [isCloseRequested, setIsCloseRequested] = useState(false);
  const [checked, setChecked] = useState<string[]>([]);
  const [responseErr, setResponseErr] = useState('');
  const { usersOptios } = useAuth();

  const onSuccess = () => {
    queryCache.invalidateQueries(QKeys.Incidents);
    queryCache.invalidateQueries([QKeys.Scenario, { scenarioId, variant: 'base' }]);
    onClose();
  };

  const onError = (err: any) => {
    setErr(err.message || 'Something went wrong');
  };

  const formik = useFormik({
    initialValues: incidentInitialValues,
    validationSchema: incidentValidationSchema,
    validateOnMount: true,
    enableReinitialize: true,

    onSubmit: async (values) => {
      setResponseErr('');

      const { userAdd } = selectUserOption({
        usersOptios,
        selectedUser: values.owner,
      });

      const incident = await createIncident([{ ...values, owner: userAdd }]).catch((err) =>
        setResponseErr(err.message || 'Something went wrong'),
      );

      if (!incident) {
        return null;
      }

      return linkIncident({
        incidentId: incident.id,
        scenarios: [scenarioId],
      })
        .then(onSuccess)
        .catch(onError);
    },
  });

  const [add, { isLoading }] = useMutation(
    async () => {
      const arr = checked.map((el) =>
        linkIncident({
          incidentId: el,
          scenarios: [scenarioId],
        }),
      );

      return await Promise.all(arr);
    },
    {
      onMutate: () => {
        setErr('');
      },
      onSuccess,
      onError,
    },
  );

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={onClose}
      isOpen={isCloseRequested}
      isDirty={!!checked.length || formik.dirty}
      modalComponent={
        <StyledModal onRequestClose={() => setIsCloseRequested(true)} isOpen center={false}>
          <div className="content">
            <Spacer $px={40} />
            <div className="h-padding">
              <Poppins className="m-title" px={28}>
                Link Incidents
              </Poppins>
              {subTitle && <ModalSubTitle title={subTitle.title} value={subTitle.value} />}
              <Spacer $px={20} />
              <TabsMenu
                tabs={[
                  { label: 'Enter Incident Details', value: 'from_new', dataCy: 'from_new' },
                  { label: 'Link Exisiting Incidents', value: 'from_existing', dataCy: 'from_existing' },
                ]}
                activeTab={activeTab}
                setActiveTab={(tab) => {
                  setActiveTab(tab);
                  mpEvent(MPEvents.ButtonClick, {
                    button: tab === 'from_new' ? 'Tab:Enter Incident Details' : 'Tab:Link Exisiting Incidents',
                    modal: 'Link incidents to scenario modal',
                    tags: ['SCENARIO', 'INCIDENT'],
                  });
                }}
              />
              <Spacer $px={40} />
              {activeTab === 'from_existing' && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0, duration: 0.1 }}>
                  <FromExisting
                    scenarioId={scenarioId}
                    setChecked={setChecked}
                    isLoading={isLoading || !checked.length}
                    onSubmit={add}
                  />
                </motion.div>
              )}
              {activeTab === 'from_new' && (
                <FormikProvider value={formik}>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0, duration: 0.1 }}
                  >
                    <IncidentForm
                      buttonTitle="CREATE"
                      onSubmitCb={() =>
                        mpEvent(MPEvents.ButtonClick, {
                          button: 'Create',
                          modal: 'Link incidents to scenario modal',
                          tags: ['SCENARIO', 'INCIDENT'],
                        })
                      }
                    />
                  </motion.div>
                </FormikProvider>
              )}
            </div>
          </div>
          <div className="h-padding">
            {(!!err || !!responseErr) && (
              <>
                <Spacer $px={15} />
                <div className="error">{err || responseErr}</div>
              </>
            )}
          </div>
          <Spacer $px={30} />
        </StyledModal>
      }
    />
  );
};
