import React, { useState } from 'react';
import { Incident } from 'src/api/types';
import { DataTitle, DataValue, NoData, PageCard, Poppins, Settings, Spacer, TitleWithBack } from 'src/common';
import ExpandCollapse from 'src/common/ExpandCollapse';
import colors from 'src/theme/colors';
import styled from 'styled-components';
import CommonMenu from 'src/components/CommonMenu';
import { currencyFormatter, formatDate, formatUrl } from 'src/utils/misc';
import { OpenInNew } from '@mui/icons-material';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import useVersion from 'src/hooks/useVersion';

const WhiteBox = styled(PageCard)`
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .grid-view {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 50px;
    max-width: 900px;
  }
  .panel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 15px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .grid-view {
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }
  }
`;

interface MetaSheetProps {
  data: Incident;
  editable?: { onEditSelect: () => void; onDeleteSelect: () => void };
  children?: React.ReactNode;
}

const MetaSheet: React.FC<MetaSheetProps> = ({ data, editable, children }) => {
  const [anchorEl, setAnchorEl] = useState<React.MouseEvent['currentTarget'] | null>(null);
  const { version, VHeader } = useVersion();

  const onMenuClick = (e: React.MouseEvent<HTMLLIElement>, cb: () => void) => {
    e.stopPropagation();
    handleClose();
    cb();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <WhiteBox>
      <div className="head">
        <TitleWithBack title="SECURITY INCIDENT" gradient />
        {editable && !version && (
          <>
            <Settings
              active={!!anchorEl}
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
              }}
              data-cy="common-menu"
            />
            <CommonMenu
              onEditClick={(e) => onMenuClick(e, () => editable.onEditSelect())}
              onDeleteClick={(e) => onMenuClick(e, () => editable.onDeleteSelect())}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            />
          </>
        )}
      </div>
      <Spacer $px={30} />
      <Poppins px={32} weight={600} data-cy="incident-title">
        {data.name}
      </Poppins>
      {VHeader && (
        <>
          <Spacer $px={5} />
          {VHeader}
        </>
      )}
      <Spacer $px={30} />
      <div className="grid-view">
        <div>
          <DataTitle>REFERENCE ID</DataTitle>
          {data.refId ? (
            <DataValue>
              {formatUrl(data.source) ? (
                <a
                  className="table-link"
                  data-cy="refid-link"
                  href={formatUrl(data.source)}
                  target="_blank"
                  onClick={() =>
                    mpEvent(MPEvents.Link, {
                      source: { value: ['Incident'], params: { id: data.id } },
                      destination: { value: ['External URL'] },
                      tags: ['INCIDENT'],
                    })
                  }
                >
                  {data.refId}{' '}
                  <OpenInNew
                    css={`
                      font-size: 14px !important;
                      color: ${colors.prussianBlue};
                    `}
                  />
                </a>
              ) : (
                data.refId
              )}
            </DataValue>
          ) : (
            <NoData />
          )}
        </div>
      </div>
      <Spacer $px={40} />
      <div className="grid-view">
        <div>
          <DataTitle>OWNER</DataTitle>
          {data.owner?.text ? <DataValue>{data.owner.text}</DataValue> : <NoData />}
        </div>
        <div>
          <DataTitle>DATE OF INCIDENT</DataTitle>
          {data.date ? <DataValue>{formatDate(data.date)}</DataValue> : <NoData />}
        </div>
        <div>
          <DataTitle>TYPE</DataTitle>
          {data.type ? <DataValue>{data.type}</DataValue> : <NoData />}
        </div>
      </div>
      <Spacer $px={40} />
      <div className="grid-view">
        <div>
          <DataTitle>IMPACT</DataTitle>
          {data.impact ? <DataValue>{data.impact}</DataValue> : <NoData />}
        </div>
        <div>
          <DataTitle>IMPACT - LOWER</DataTitle>
          {data.financialImpactLower !== null ? (
            <DataValue>{currencyFormatter(data.financialImpactLower)}</DataValue>
          ) : (
            <NoData />
          )}
        </div>
        <div>
          <DataTitle>IMPACT - UPPER</DataTitle>
          {data.financialImpactUpper !== null ? (
            <DataValue>{currencyFormatter(data.financialImpactUpper)}</DataValue>
          ) : (
            <NoData />
          )}
        </div>
      </div>
      <Spacer $px={40} />

      <div>
        <DataTitle>DESCRIPTION</DataTitle>
        {data.description ? <ExpandCollapse text={data.description} /> : <NoData />}
      </div>
      <Spacer $px={0} />
      {children}
    </WhiteBox>
  );
};

export default MetaSheet;
