import { format } from 'date-fns';
import React from 'react';
import { Poppins, Spacer } from 'src/common';
import { DATE_FORMAT } from 'src/config';
import styled from 'styled-components';

const Div = styled.div`
  display: flex;
  align-items: center;
`;

interface VersionStringProps {
  name: string;
  userName: string;
  createdAt: string;
}

export const VersionString: React.FC<VersionStringProps> = ({ name, userName, createdAt }) => {
  return (
    <Div data-cy="version-string" data-version-name={name}>
      <Poppins px={14} color="cflowerBlue">
        {name}
      </Poppins>
      <Spacer $px={6} $horizontal />
      <Poppins px={14} color="cflowerBlue">
        |
      </Poppins>
      <Spacer $px={6} $horizontal />
      <Poppins px={14} color="cflowerBlue">
        {userName}
      </Poppins>
      <Spacer $px={6} $horizontal />
      <Poppins px={14} color="cflowerBlue">
        |
      </Poppins>
      <Spacer $px={6} $horizontal />
      {createdAt && (
        <Poppins px={14} color="cflowerBlue">
          {format(new Date(createdAt), DATE_FORMAT)}
        </Poppins>
      )}
    </Div>
  );
};
