import { Control } from 'src/api/types';
import { formatDate, sanitizeForCSV } from 'src/utils/misc';
import Papa from 'papaparse';
import { ControlCsvHeaders } from 'src/api/types/misc';

export const downloadCsv = (data: Control[]) => {
  const rows = [
    [
      ControlCsvHeaders.ID,
      ControlCsvHeaders.Name,
      ControlCsvHeaders.Strategy,
      ControlCsvHeaders.Description,
      ControlCsvHeaders.Owner,
      ControlCsvHeaders.Framework,
      ControlCsvHeaders.FrameworkID,
      ControlCsvHeaders.Status,
      ControlCsvHeaders.ExpectedLiveDate,
      ControlCsvHeaders.ActualLiveDate,
      ControlCsvHeaders.ReviewDate,
      ControlCsvHeaders.AnnualCost,
      ControlCsvHeaders.Vendor,

      ControlCsvHeaders.Created,
      ControlCsvHeaders.Updated,
    ],
    ...data.map((el) => [
      sanitizeForCSV(el.id),
      sanitizeForCSV(el.name),
      sanitizeForCSV(el.strategy),
      sanitizeForCSV(el.description),
      sanitizeForCSV(el.owner?.text),
      sanitizeForCSV(el.frameworkLibrary_shortname),
      sanitizeForCSV(el.controlId),
      sanitizeForCSV(el.status),
      formatDate(el.expected_active_date),
      formatDate(el.actual_active_date),
      formatDate(el.review_date || ''),
      sanitizeForCSV(el.annual_cost),
      sanitizeForCSV(el.vendor),

      formatDate(el.created_at),
      formatDate(el.updated_at),
    ]),
  ];
  const csvString = Papa.unparse(rows);
  const anchor = document.createElement('a');
  anchor.href = 'data:attachment/csv;charset=utf-8,' + encodeURIComponent(csvString);
  anchor.target = '_blank';
  anchor.download = `Risk Management Plan Export.csv`;
  anchor.click();
};
