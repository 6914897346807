import React, { useEffect, useState } from 'react';
import { Assessment, AssessmentVersion, Scenario } from 'src/api/types';
import { AssessmentView } from './AssessmentView';
import { Spacer } from 'src/common';
import ComparisonView from './ComparisonView';
import { ScenariosComparison, compareScenarios } from './util';
import { useNavigate } from 'react-router-dom';
import { encodeQueryParams } from 'src/api/client';
import TabsMenu from 'src/components/TabsMenu';
import { DATE_FORMAT } from 'src/config';
import { format } from 'date-fns';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const withEcpectedLossRounded = (scenarios: Scenario[]) => {
  return scenarios.map((el) => {
    return { ...el, expected_loss: Math.round(el.expected_loss) };
  });
};

const getVersionTooltip = (version?: AssessmentVersion) => {
  if (!version) return '[CURRENT]';
  return `${version.name} | ${version.userName} | ${format(new Date(version.created_at), DATE_FORMAT)}`;
};

interface ScenariosCompareProps {
  dataA: Assessment;
  dataB: Assessment;
  versionA?: AssessmentVersion;
  versionB?: AssessmentVersion;
}

const ScenariosCompare: React.FC<ScenariosCompareProps> = ({ dataA, dataB, versionA, versionB }) => {
  const [tab, setTab] = useState<1 | 2 | 3>(1);
  const [comparison, setComparison] = useState<ScenariosComparison | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const res = compareScenarios(
      withEcpectedLossRounded(dataA.scenarios),
      withEcpectedLossRounded(dataB.scenarios),
      'reference_id',
    );
    setComparison(res);
  }, [dataA, dataB]);

  const handleOnScenarioClick = (scenario: Scenario, from: 'A' | 'B') => {
    let scenA: Scenario | undefined = from === 'A' ? scenario : undefined;
    let scenB: Scenario | undefined = from === 'B' ? scenario : undefined;

    if (from === 'A') {
      scenB = dataB.scenarios.find((el) => el.reference_id === scenario.reference_id);
    } else {
      scenA = dataA.scenarios.find((el) => el.reference_id === scenario.reference_id);
    }

    if (!scenA && !scenB) {
      return;
    }
    if (scenA && scenB) {
      navigate(
        `/compare/scenarios/${scenA.assessment_id}/${scenB.assessment_id}/${scenA.id}/${scenB.id}${encodeQueryParams({
          vaId: versionA?.id,
          vbId: versionB?.id,
        })}`,
      );
      return;
    }
    if (scenA) {
      navigate(
        `/risk-assessments/${scenA.assessment_id}/scenario/${scenA.id}${encodeQueryParams({
          version: versionA?.id,
        })}`,
      );
    }
    if (scenB) {
      navigate(
        `/risk-assessments/${scenB.assessment_id}/scenario/${scenB.id}${encodeQueryParams({ version: versionB?.id })}`,
      );
    }
  };

  const genPath = (scenario: Scenario, from: 'A' | 'B') => {
    let scenA: Scenario | undefined = from === 'A' ? scenario : undefined;
    let scenB: Scenario | undefined = from === 'B' ? scenario : undefined;

    if (from === 'A') {
      scenB = dataB.scenarios.find((el) => el.reference_id === scenario.reference_id);
    } else {
      scenA = dataA.scenarios.find((el) => el.reference_id === scenario.reference_id);
    }

    if (!scenA && !scenB) {
      return;
    }

    if (scenA && scenB) {
      return `/compare/scenarios/${scenA.assessment_id}/${scenB.assessment_id}/${scenA.id}/${
        scenB.id
      }${encodeQueryParams({
        vaId: versionA?.id,
        vbId: versionB?.id,
      })}`;
    }

    if (scenA) {
      return `/risk-assessments/${scenA.assessment_id}/scenario/${scenA.id}${encodeQueryParams({
        version: versionA?.id,
      })}`;
    }

    if (scenB) {
      return `/risk-assessments/${scenB.assessment_id}/scenario/${scenB.id}${encodeQueryParams({
        version: versionB?.id,
      })}`;
    }

    return '';
  };

  return (
    <>
      <TabsMenu
        activeTab={tab}
        setActiveTab={(tab) => {
          setTab(tab);
          mpEvent(MPEvents.ButtonClick, {
            button: tab === 1 ? 'Tab:Assessment A' : tab === 2 ? 'Tab:Assessment B' : 'Tab:Comparison View',
          });
        }}
        tabs={[
          {
            label: dataA.name,
            tooltip: getVersionTooltip(versionA),
            value: 1,
          },
          {
            label: dataB.name,
            tooltip: getVersionTooltip(versionB),
            value: 2,
          },
          {
            label: 'Comparison View',
            value: 3,
          },
        ]}
      />
      <Spacer $px={30} />
      {tab === 1 && <AssessmentView data={dataA} genPath={(el) => genPath(el, 'A')} />}
      {tab === 2 && <AssessmentView data={dataB} genPath={(el) => genPath(el, 'B')} />}
      {tab === 3 && (
        <ComparisonView
          dataA={dataA}
          dataB={dataB}
          comparison={comparison}
          onScenarioClick={handleOnScenarioClick}
          genPath={genPath}
          versionA={versionA}
          versionB={versionB}
        />
      )}
    </>
  );
};

export default ScenariosCompare;
