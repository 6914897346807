import React, { memo, useEffect, useState } from 'react';
import { AuditItem, QKeys } from 'src/api/types';
import { Poppins, Spacer, TableSkeleton } from 'src/common';
import { queryCache, useInfiniteQuery } from 'react-query';
import { DATE_FORMAT } from 'src/config';
import { isValid, format } from 'date-fns';
import { getAudit } from 'src/api/other';
import _ from 'lodash';
import { useAuditColumns } from '../GenericTable/sharedColumns/useAuditColumns';
import GenericTable from '../GenericTable';
import { ActionsGrid, Switch } from './comps';
import { composeSentence } from './comps/util';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { TableIds } from 'src/api/types/misc';

interface AuditProps {
  noHide?: boolean;
  maxHeight?: number;
  query: {
    user?: string | number;
    assessmentId?: string;
    scenarioId?: string;
    version?: string;
    organisation?: string;
    workspace?: string;
    endPath?: 'controls';
    controlId?: string;
  };
  customLabel?: {
    open: string;
    close: string;
  };
  tableId: TableIds;
}

const Audit: React.FC<AuditProps> = memo(
  ({ noHide, maxHeight = 700, query, customLabel, tableId }) => {
    const [showHistory, setShowHistory] = useState(!!noHide);
    const [desc, setDesc] = useState(true);
    const [sortBy, setSortBy] = useState('day');
    const [items, setItems] = useState<AuditItem[]>([]);
    const [skip, setSkip] = useState(0);

    const { canFetchMore, isFetching, refetch } = useInfiniteQuery(
      [QKeys.Audit, { sortBy, desc, skip, ...query }],
      () =>
        getAudit({
          skip,
          orderBy: sortBy,
          dir: desc ? 'desc' : 'asc',
          ...query,
        }),
      {
        // onSuccess: (data) => {
        //   setItems((prev) => [...(prev || []), ...data.map((el) => el.items).flat()]);
        // },
        onSuccess: (data) => {
          if (data.length === 0 || skip === 0) {
            setItems(data.flatMap((el) => el.items)); // Replace existing items if it's the first load or no items.
          } else {
            setItems((prev) => [...prev, ...data.flatMap((el) => el.items)]); // Append only if more items are fetched.
          }
        },
        refetchOnMount: true,
        // getFetchMore: (lastGroup, allGroups) => {
        //   const allGroupsLength = allGroups.reduce((acc, group) => acc + group.items.length, 0);
        //   const moreItemsExist = allGroupsLength <= lastGroup.total;
        //   if (moreItemsExist) {
        //     return allGroups.reduce((acc, group) => acc + group.items.length, 0);
        //   } else {
        //     return false;
        //   }
        // },
        getFetchMore: (lastGroup, allGroups) => {
          const itemsFetched = allGroups.reduce((acc, group) => acc + group.items.length, 0);
          const moreItemsExist = itemsFetched < lastGroup.total; // Ensure this compares correctly
          return moreItemsExist ? itemsFetched : false;
        },
      },
    );

    const itemsComposed = items.map((el) => {
      const date = new Date(el.date_time);
      return {
        ...el,
        date_time: isValid(date) ? format(date, `${DATE_FORMAT}, HH:mm`) : '',
        action_title: el.action_title || composeSentence(el.actions),
      };
    });

    const { columns, GTColumnSelectAnchorExported } = useAuditColumns({
      data: itemsComposed,
      tableId,
    });

    useEffect(() => {
      setItems([]);
      setSkip(0);
      refetch();
    }, [query, sortBy, desc]);

    return (
      <>
        {!noHide && (
          <Switch
            title={customLabel ? (showHistory ? customLabel.open : customLabel.close) : ''}
            showHistory={showHistory}
            setShowHistory={(val) => {
              setShowHistory(val);
              mpEvent(MPEvents.AuditView, {
                value: val ? 'open' : 'close',
              });
            }}
          />
        )}
        {showHistory && (
          <>
            {isFetching && !items.length ? (
              <>
                <Spacer $px={30} />
                <TableSkeleton />
              </>
            ) : (
              <>
                {!items?.length ? (
                  <>
                    <Spacer $px={30} />
                    <Poppins px={14} color="cflowerBlue">
                      No activity recorded yet
                    </Poppins>
                  </>
                ) : (
                  <>
                    <Spacer $px={30} />
                    <GenericTable
                      tableId={tableId}
                      data={itemsComposed}
                      columns={columns}
                      GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
                      expandContent={(data) =>
                        data.actions?.length ? <ActionsGrid actions={data.actions || []} /> : undefined
                      }
                      controledSorting={{
                        state: { id: sortBy, desc },
                        onChange: ({ id, desc }) => {
                          setSortBy(id);
                          setDesc(desc);
                        },
                      }}
                      onBottomReached={() => {
                        if (canFetchMore && !isFetching) {
                          setSkip(items.length + 1);
                          // setSkip((prev) => prev + 1);
                        }
                      }}
                      layout={{
                        maxHeight,
                        stickyHeader: true,
                      }}
                      usePagination={null}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  },
  (prev, next) => _.isEqual(prev, next),
);

export default Audit;
