import {
  AuditAction,
  RiskConsequences,
  RiskEvents,
  RiskSources,
  riskConsequencesNames,
  riskEventsNames,
  riskSourcesNames,
} from 'src/api/types';

export const constantsNaming = (key: string, value: string): string => {
  switch (key) {
    case 'Event':
      return riskEventsNames[parseInt(value) as RiskEvents];
    case 'Consequence':
      return riskConsequencesNames[parseInt(value) as RiskConsequences];
    case 'Source':
      return riskSourcesNames[parseInt(value) as RiskSources];

    default:
      return value || '[empty]';
  }
};

type ComposeSentence = (actions: AuditAction[] | null) => string;

export const composeSentence: ComposeSentence = (actions) => {
  if (!actions) return '';

  let sentence = `Changed `;

  actions.forEach((el, idx) => {
    // const str = `${el.field_name}  ${constantsNaming(el.field_name, el.after)}`;
    const str = `${el.field_name} from ${constantsNaming(el.field_name, el.before)} to ${constantsNaming(
      el.field_name,
      el.after,
    )}`;

    if (idx === 0) {
      sentence += str;
    } else {
      sentence += `, ${str}`;
    }

    if (idx === actions.length - 1) {
      sentence += '.';
    }
  });

  return sentence;
};
