import React, { useState } from 'react';
import { Poppins, Spacer, TitleWithBack } from 'src/common';
import styled, { css } from 'styled-components';
import Input from 'src/components/form/Input';
import colors from 'src/theme/colors';
import { LogSwitch } from 'src/components/charts/comps';
import { Assessment, AssessmentVersion } from 'src/api/types';
import Versions from './Versions';
import { VersionString } from '../../comps';
import { Tooltip } from 'react-tooltip';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const Div = styled.div`
  .panel {
    /* border: 1px solid red; */
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    align-items: center;
    position: relative;

    &__head {
      display: grid;
      grid-template-columns: minmax(10px, auto) minmax(10px, auto) minmax(10px, auto) minmax(10px, auto);
      grid-gap: 10px;
      margin-right: auto;
      align-items: center;

      &__versions-controll {
        height: 30px;
        width: 30px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__title {
      font-weight: 600;
      color: ${colors.prussianBlue};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const VersionArrow = styled.svg<{ $isOpen: boolean }>`
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;

interface HeadProps {
  dataA: Assessment;
  dataB: Assessment;
  versionA?: AssessmentVersion;
  versionB?: AssessmentVersion;
  isLogarithmic: boolean;
  setIsLogarithmic: (val: boolean) => void;
}

export const Head: React.FC<HeadProps> = ({ dataA, dataB, versionA, versionB, isLogarithmic, setIsLogarithmic }) => {
  const [isVersionsOpen, setIsVersionsOpen] = useState(false);

  return (
    <Div>
      <div className="panel">
        {isVersionsOpen && (
          <Versions assesmentA={dataA} assesmentB={dataB} handleClose={() => setIsVersionsOpen(false)} />
        )}
        <div className="panel__head">
          <TitleWithBack title={`${dataA.name}/${dataB.name}`} />
          <div
            className="panel__head__versions-controll"
            onClick={() => {
              setIsVersionsOpen(!isVersionsOpen);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Versions',
                value: isVersionsOpen ? 'Close' : 'Open',
              });
            }}
          >
            <VersionArrow
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="6"
              viewBox="0 0 11 6"
              fill="none"
              $isOpen={isVersionsOpen}
            >
              <path
                d="M9.88629 0H0.659141C0.480523 0 0.326108 0.0652146 0.195643 0.195535C0.0651783 0.326 0 0.480415 0 0.658925C0 0.837435 0.0651783 0.991959 0.195643 1.12235L4.80923 5.73587C4.93984 5.86634 5.09426 5.93166 5.27273 5.93166C5.45121 5.93166 5.60577 5.86634 5.73612 5.73587L10.3497 1.12232C10.48 0.991958 10.5455 0.837435 10.5455 0.658889C10.5455 0.480415 10.4801 0.326 10.3497 0.195499C10.2194 0.06507 10.0648 0 9.88629 0Z"
                fill={colors.cflowerBlue}
              />
            </VersionArrow>
          </div>
        </div>
      </div>
      {(versionA || versionB) && <Spacer $px={5} />}
      {versionA && <VersionString name={versionA.name} userName={versionA.userName} createdAt={versionA.created_at} />}
      {versionB && <VersionString name={versionB.name} userName={versionB.userName} createdAt={versionB.created_at} />}
      <Spacer $px={20} />
      <div className="lec">
        <Poppins className="gradient-text" px={18}>
          LOSS EXCEEDANCE CURVE
        </Poppins>
        <LogSwitch isLogarithmic={isLogarithmic} setIsLogarithmic={setIsLogarithmic} />
      </div>
    </Div>
  );
};
