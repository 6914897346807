import React, { useState } from 'react';
import colors from 'src/theme/colors';
import styled from 'styled-components';
import { BottomData } from '../../../comps/BottomData';
import { getMinMax, parseRiskTolerance } from 'src/components/charts/util';
import { PageCard, Spacer } from 'src/common';
import { Assessment, AssessmentVersion } from 'src/api/types';
import { Head } from '../Head';
import Chart from 'src/components/charts/Chart';

const Div = styled.div`
  .lec {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .gradient-text {
    background: ${colors.gradient};
    -webkit-background-clip: text;
    font-weight: bold;
    text-transform: uppercase;
    color: transparent;
  }
`;

interface ChartsProps {
  dataA: Assessment;
  dataB: Assessment;
  versionA?: AssessmentVersion;
  versionB?: AssessmentVersion;
}

export const Charts: React.FC<ChartsProps> = ({ dataA, dataB, versionA, versionB }) => {
  const [isLogarithmic, setIsLogarithmic] = useState(true);
  const [seriesAEnabled, setSeriesAEnabled] = useState(true);
  const [seriesBEnabled, setSeriesBEnabled] = useState(true);
  const [seriesARiskEnabled, setSeriesARiskEnabled] = useState(true);
  const [seriesBRiskEnabled, setSeriesBRiskEnabled] = useState(true);

  const charts = [
    {
      isEnabled: seriesAEnabled,
      params: {
        data: dataA.chart || [],
        upper: getMinMax(dataA?.scenarios || []).upper || 1,
        lower: getMinMax(dataA?.scenarios || []).lower || 1,
        name: 'Loss Exceedance A',
        color: colors.red,
        fillColor: colors.compareAFill,
        legendId: 'leg-ca',
      },
    },
    {
      isEnabled: seriesBEnabled,
      params: {
        data: dataB.chart || [],
        upper: getMinMax(dataB?.scenarios || []).upper || 1,
        lower: getMinMax(dataB?.scenarios || []).lower || 1,
        name: 'Loss Exceedance B',
        color: colors.compareBLine,
        fillColor: colors.compareBFill,
        legendId: 'leg-cb',
      },
    },
    {
      isEnabled: seriesARiskEnabled,
      params: {
        data: parseRiskTolerance(dataA.risk_tolerance),
        name: 'Risk Tolerance A',
        type: 'spline',
        dashStyle: 'ShortDash',
        color: colors.red,
        legendId: 'leg-car',
        isRiskTolerance: true,
      },
    },
    {
      isEnabled: seriesBRiskEnabled,
      params: {
        data: parseRiskTolerance(dataB.risk_tolerance),
        name: 'Risk Tolerance B',
        type: 'spline',
        dashStyle: 'ShortDash',
        color: colors.compareBLine,
        legendId: 'leg-cbr',
        isRiskTolerance: true,
      },
    },
  ]
    .filter((el) => el.isEnabled)
    .map((el) => el.params);

  return (
    <Div>
      <PageCard>
        <Head
          dataA={dataA}
          dataB={dataB}
          versionA={versionA}
          versionB={versionB}
          isLogarithmic={isLogarithmic}
          setIsLogarithmic={setIsLogarithmic}
        />
        <Spacer $px={10} />
        <Chart isLogarithmic={isLogarithmic} input={charts} />
        <BottomData
          nameA={dataA.name}
          nameB={dataB.name}
          dateA={dataA.review_date}
          dateB={dataB.review_date}
          onSetSeriesA={() => setSeriesAEnabled(!seriesAEnabled)}
          onSetSeriesB={() => setSeriesBEnabled(!seriesBEnabled)}
          onSetSeriesARisk={() => setSeriesARiskEnabled(!seriesARiskEnabled)}
          onSetSeriesBRisk={() => setSeriesBRiskEnabled(!seriesBRiskEnabled)}
        />
      </PageCard>
    </Div>
  );
};
