import React, { useState } from 'react';
import { Modal } from './comps';
import styled from 'styled-components';
import { Poppins, Spacer } from 'src/common';
import colors from 'src/theme/colors';
import Button from '../form/Button';
import CheckBox from '../form/CheckBox';
import { gaEventEXPORT } from 'src/utils/gaEvent';
import { Control } from 'src/api/types';
import PdfRiskManagement from '../pdf/PdfRiskManagement';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const StyledModal = styled(Modal)`
  width: 540px;
  min-height: 200px;
  overflow-y: auto;
  max-height: 100%;
  padding: 0;

  .container {
    display: grid;
  }
  .subtitle {
    color: ${colors.davysGrey};
    font-size: 12px;
  }
  .scenarios-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      width: 200px;
    }

    &__switch {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-gap: 8px;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 14px;
        font-weight: 500;
        color: ${colors.cflowerBlue};
      }
    }
  }

  .checklist {
    &__row {
      margin-bottom: 11px;
      display: flex;
      grid-gap: 11px;
      align-items: center;
      user-select: none;

      &__label {
        font-size: 14px;
        font-weight: 500;
        color: ${colors.davysGrey};
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: ${colors.stroke};
  }
`;

interface PdfExportModalRMProps {
  onClose: () => void;
  controls: Control[];
}

export const PdfExportModalRM: React.FC<PdfExportModalRMProps> = ({ onClose, controls }) => {
  const [selectedControls, setSelectedControls] = useState<string[]>(controls.map((el) => el.id));
  const [openPrint, setOpenPrint] = useState(false);

  const selectAll = () => {
    if (selectedControls.length === controls.length) {
      setSelectedControls([]);
    } else {
      setSelectedControls(controls.map((el) => el.id) || []);
    }
  };

  return (
    <>
      {openPrint && (
        <PdfRiskManagement data={controls.filter((el) => selectedControls.includes(el.id))} onClose={onClose} />
      )}
      <StyledModal onRequestClose={() => onClose()} isOpen isLoading={openPrint}>
        <Spacer $px={54} />
        <div className="h-padding">
          <Poppins className="m-title" px={28}>
            Export PDF
          </Poppins>
        </div>
        <Spacer $px={15} />
        <div className="divider" />
        <Spacer $px={24} />
        <div className="h-padding">
          <>
            <Poppins className="subtitle">You can choose which controls to include in the export</Poppins>
            <Spacer $px={19} />
            <div className="checklist">
              <div className="checklist__row">
                <CheckBox isChecked={selectedControls.length === controls.length} onClick={selectAll} />
                <Poppins className="checklist__row__label" nowrap>
                  All controls
                </Poppins>
              </div>
              {controls.map((el) => (
                <div className="checklist__row" key={el.id}>
                  <CheckBox
                    isChecked={selectedControls.includes(el.id)}
                    onClick={() => {
                      if (selectedControls.includes(el.id)) {
                        setSelectedControls(selectedControls.filter((item) => item !== el.id));
                      } else {
                        setSelectedControls([...selectedControls, el.id]);
                      }
                    }}
                  />
                  <Poppins className="checklist__row__label" nowrap>
                    {el.name}
                  </Poppins>
                </div>
              ))}
              {/* <Spacer $px={19} />
              <div className="scenarios-switch">
                <Poppins className="subtitle scenarios-switch__title" style={{ width: 300 }}>
                  Include the Scenarios for Risk Management export?
                </Poppins>
                <div className="scenarios-switch__switch">
                  <span>No</span>
                  <Input
                    checked={includesScenarios}
                    onChange={(e) => setIncludesScenarios(e.target.checked)}
                    className="switch"
                    type="checkbox"
                  />
                  <span>Yes</span>
                </div>
              </div> */}
            </div>
          </>

          <Spacer $px={32} />
          <div className="container">
            <Button
              disabled={openPrint || selectedControls.length === 0}
              primary
              onClick={() => {
                gaEventEXPORT('EXPORT_PDF');
                setOpenPrint(true);
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Export',
                  modal: 'Pdf export modal',
                });
              }}
              data-cy="export"
            >
              EXPORT
            </Button>
          </div>
        </div>
        <Spacer $px={47} />
      </StyledModal>
    </>
  );
};
