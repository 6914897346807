// @ts-nocheck
import { Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { Control } from 'src/api/types';
import { Header } from './Header';
import { pdfStyles } from '../pdfStyles';
import { Footer } from './Footer';
import { formatDate } from '../util';
import { NoData } from './NoData';
import { TitleLink } from './TitleLink';
import { numeralFormat } from 'src/utils/misc';

interface RmpPageProps {
  control: Control;
  onRender: (pageNumber: number) => void;
  endComponent?: React.ReactNode;
  footerComponent?: React.ReactNode;
  titleSmall?: boolean;
}

export const RmpPage: React.FC<RmpPageProps> = ({ control, onRender, endComponent, footerComponent }) => {
  console.log('control', control);
  return (
    <Page size={'A4'} style={pdfStyles.page}>
      <Header endComponent={endComponent} />

      <View render={(data) => onRender(data.pageNumber)} />
      <View style={pdfStyles.section}>
        <TitleLink
          title={control.name}
          intLinkId={control.name.toLowerCase()}
          extLink={`risk-management/control/${control.ux_id || control.id}`}
        />

        <View style={pdfStyles.spacer8} />
        <View style={pdfStyles.spacer4} />
        <View style={{ flexDirection: 'row' }}>
          <View style={{ width: 120, marginRight: 20 }}>
            <Text style={pdfStyles.label}>STRATEGY</Text>
            {control.strategy ? <Text style={pdfStyles.value}>{control.strategy}</Text> : <NoData />}
          </View>

          <View style={{ marginRight: 20 }}>
            <Text style={pdfStyles.label}>STATUS</Text>
            <View style={pdfStyles.spacer1} />
            {control.status ? <Text style={pdfStyles.value}>{control.status}</Text> : <NoData />}
          </View>
        </View>

        <View style={pdfStyles.spacer8} />

        <View style={{ flexDirection: 'row' }}>
          <View style={{ width: 120, marginRight: 20 }}>
            <Text style={pdfStyles.label}>OWNER</Text>
            {control.owner?.text ? <Text style={pdfStyles.value}>{control.owner.text}</Text> : <NoData />}
          </View>
          <View style={{ width: 120, marginRight: 20 }}>
            <Text style={pdfStyles.label}>ANNUAL COST</Text>
            <View style={pdfStyles.spacer1} />
            {control.annual_cost ? (
              <Text style={pdfStyles.value}>{numeralFormat(control.annual_cost)}</Text>
            ) : (
              <NoData />
            )}
          </View>
          <View style={{ marginRight: 20 }}>
            <Text style={pdfStyles.label}>VENDOR</Text>
            <View style={pdfStyles.spacer1} />
            {control.vendor ? <Text style={pdfStyles.value}>{control.vendor}</Text> : <NoData />}
          </View>
        </View>

        <View style={pdfStyles.spacer8} />

        <View style={{ flexDirection: 'row' }}>
          <View style={{ width: 120, marginRight: 20 }}>
            <Text style={pdfStyles.label}>FRAMEWORK ID</Text>
            <View style={pdfStyles.spacer1} />
            {control.controlId ? <Text style={pdfStyles.value}>{control.controlId}</Text> : <NoData />}
          </View>
          <View style={{ width: 320, marginRight: 20 }}>
            <Text style={pdfStyles.label}>FRAMEWORK</Text>
            <View style={pdfStyles.spacer1} />
            {control.frameworkLibrary_name ? (
              <Text style={pdfStyles.value}>{control.frameworkLibrary_name}</Text>
            ) : (
              <NoData />
            )}
          </View>
        </View>

        <View style={pdfStyles.spacer8} />
        <View style={{ flexDirection: 'row' }}>
          <View style={{ width: 120, marginRight: 20 }}>
            <Text style={pdfStyles.label}>EXPECTED LIVE DATE</Text>
            <View style={pdfStyles.spacer1} />
            {control.expected_active_date ? (
              <Text style={pdfStyles.value}>{formatDate(control.expected_active_date)}</Text>
            ) : (
              <NoData />
            )}
          </View>
          <View style={{ width: 120, marginRight: 20 }}>
            <Text style={pdfStyles.label}>ACTUAL LIVE DATE</Text>
            <View style={pdfStyles.spacer1} />
            {control.actual_active_date ? (
              <Text style={pdfStyles.value}>{formatDate(control.actual_active_date)}</Text>
            ) : (
              <NoData />
            )}
          </View>
          <View style={{ width: 120, marginRight: 20 }}>
            <Text style={pdfStyles.label}>REVIEW DATE</Text>
            <View style={pdfStyles.spacer1} />
            {control.review_date ? <Text style={pdfStyles.value}>{formatDate(control.review_date)}</Text> : <NoData />}
          </View>
          <View style={{ marginRight: 20 }}>
            <Text style={pdfStyles.label}>LAST EDITED</Text>
            <View style={pdfStyles.spacer1} />
            {control.updated_at ? <Text style={pdfStyles.value}>{formatDate(control.updated_at)}</Text> : <NoData />}
          </View>
        </View>
        <View style={pdfStyles.spacer8} />

        <Text style={pdfStyles.label}>DESCRIPTION</Text>
        {control.description ? <Text style={pdfStyles.value}>{control.description}</Text> : <NoData />}
      </View>
      <Footer footerComponent={footerComponent} />
    </Page>
  );
};
