import React, { useEffect, useState } from 'react';
import {
  Assessment,
  AssessmentVersion,
  Scenario,
  riskConsequencesNames,
  riskEventsNames,
  riskSourcesNames,
} from 'src/api/types';
import styled from 'styled-components';
import _, { orderBy } from 'lodash';
import { ModifiedScenario, ModifiedScenarioNamed, ScenariosComparison } from '../util';
import { Poppins, Spacer } from 'src/common';
import { HeadRow, Row } from './comps';
import { VersionString } from 'src/pages/compare/comps';

const Div = styled.div`
  position: relative;
  overflow-y: hidden;

  .grid {
    display: grid;
    grid-template-columns: minmax(200px, 600px) minmax(470px, 1500px) minmax(470px, 1500px);
    grid-gap: 10px;

    .title {
      line-height: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .label {
      margin-left: 8px;
      margin-bottom: 2px;
    }

    .scenarios-ammount {
      display: flex;
      align-items: flex-end;
    }

    .version-string {
      position: absolute;
      &__empty {
        transform: translateY(-5px);
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    .grid {
      grid-template-columns: minmax(150px, 600px) minmax(370px, 1500px) minmax(370px, 1500px);
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .grid {
      grid-template-columns: minmax(120px, 600px) minmax(260px, 1500px) minmax(260px, 1500px);
      .scenarios-ammount {
        margin-top: 5px;
      }
      .label {
        margin-left: 4px;
        margin-bottom: 1px;
      }
    }
  }
`;

interface ComparisonViewProps {
  dataA: Assessment;
  dataB: Assessment;
  versionA?: AssessmentVersion;
  versionB?: AssessmentVersion;
  comparison: ScenariosComparison | null;
  onScenarioClick: (scenario: Scenario, from: 'A' | 'B') => void;
  genPath: (a: Scenario, from: 'A' | 'B') => string | undefined;
}

interface ComparedScenario {
  rowA: ModifiedScenario | undefined;
  rowB: ModifiedScenario | undefined;
  dataChanges?: boolean;
}
interface ComparedScenarioNamed {
  rowA: ModifiedScenarioNamed | undefined;
  rowB: ModifiedScenarioNamed | undefined;
  dataChanges?: boolean;
}

const getComparedScenarios = (comparison: ScenariosComparison | null): ComparedScenarioNamed[] => {
  const temp: ComparedScenario[] = [];

  comparison?.compareA.exactMatch.forEach((el, idx) => {
    temp.push({ rowA: el, rowB: comparison.compareB.exactMatch[idx] });
  });
  comparison?.compareA.dataChanges.forEach((el, idx) => {
    temp.push({ rowA: el, rowB: comparison.compareB.dataChanges[idx], dataChanges: true });
  });
  comparison?.compareA.unique.forEach((el) => {
    temp.push({ rowA: el, rowB: undefined });
  });
  comparison?.compareB.unique.forEach((el) => {
    temp.push({ rowA: undefined, rowB: el });
  });

  return temp.map((el) => {
    return {
      ...el,
      rowA: el.rowA
        ? {
            ...el.rowA,
            source: { ...el.rowA.source, value: riskSourcesNames[el.rowA.source.value] },
            consequence: { ...el.rowA.consequence, value: riskConsequencesNames[el.rowA.consequence.value] },
            event: { ...el.rowA.event, value: riskEventsNames[el.rowA.event.value] },
          }
        : undefined,
      rowB: el.rowB
        ? {
            ...el.rowB,
            source: { ...el.rowB.source, value: riskSourcesNames[el.rowB.source.value] },
            consequence: { ...el.rowB.consequence, value: riskConsequencesNames[el.rowB.consequence.value] },
            event: { ...el.rowB.event, value: riskEventsNames[el.rowB.event.value] },
          }
        : undefined,
    };
  });
};

const ComparisonView: React.FC<ComparisonViewProps> = ({
  comparison,
  dataA,
  dataB,
  onScenarioClick,
  versionA,
  versionB,
  genPath,
}) => {
  const [comparedScenarios, setComparedScenarios] = useState<ComparedScenarioNamed[]>([]);
  const [sort, setSort] = useState<{ isAsc: boolean; selectedSort: string; type: 'A' | 'B' }>({
    isAsc: false,
    selectedSort: '',
    type: 'A',
  });

  const genPathInner = (referenceId: string, from: 'A' | 'B') => {
    const scen =
      from === 'A'
        ? dataA.scenarios.find((el) => el.reference_id === referenceId)
        : dataB.scenarios.find((el) => el.reference_id === referenceId);

    return genPath(scen!, from) || '';
  };

  useEffect(() => {
    const temp = getComparedScenarios(comparison);
    let sorted: ComparedScenarioNamed[] = [];

    if (sort.selectedSort === 'name') {
      sorted = _.orderBy(
        temp,
        [
          (item) => {
            const row = (item.rowA || item.rowB) as ModifiedScenarioNamed;
            return row.name.value.toLowerCase();
          },
        ],
        [!sort.isAsc ? 'asc' : 'desc'],
      );
    } else {
      sorted = _.orderBy(
        temp,
        [
          (item) => {
            const value = _.get(item, `row${sort.type}.${sort.selectedSort}.value`);
            return value === undefined;
          },
          `row${sort.type}.${sort.selectedSort}.value`,
        ],
        ['asc', !sort.isAsc ? 'asc' : 'desc'],
      );
    }

    setComparedScenarios(sorted);
  }, [comparison, sort]);

  // initial sort by cretaed_at
  useEffect(() => {
    const temp = getComparedScenarios(comparison);

    const sorted = orderBy(temp, [
      (item) => {
        const row = (item.rowA || item.rowB) as ModifiedScenarioNamed;
        return row.created_at.value;
      },
    ]);
    setComparedScenarios(sorted);
  }, [comparison]);

  if (!comparison) return null;

  return (
    <Div className="styled-scroll">
      <div className="grid">
        <div />
        <div>
          <Poppins className="title" color="prussianBlue" weight={600} px={22}>
            {dataA.name}
          </Poppins>
          <div className="version-string">
            {versionA ? (
              <VersionString name={versionA.name} userName={versionA.userName} createdAt={versionA.created_at} />
            ) : (
              <div className="version-string__empty">
                <Poppins px={14} color="cflowerBlue">
                  [CURRENT]
                </Poppins>
              </div>
            )}
          </div>
          <Spacer $px={20} />
          <div className="scenarios-ammount">
            <Poppins className="title" color="prussianBlue" weight={600} px={22}>
              {dataA.scenarios.length}
            </Poppins>
            <Poppins className="label" color="cflowerBlue" px={18}>
              scenario{dataA.scenarios.length ? 's' : ''}
            </Poppins>
          </div>
        </div>
        <div>
          <Poppins className="title" color="prussianBlue" weight={600} px={22}>
            {dataB.name}
          </Poppins>
          <div className="version-string">
            {versionB ? (
              <VersionString name={versionB.name} userName={versionB.userName} createdAt={versionB.created_at} />
            ) : (
              <div className="version-string__empty">
                <Poppins px={14} color="cflowerBlue">
                  [CURRENT]
                </Poppins>
              </div>
            )}
          </div>
          <Spacer $px={20} />
          <div className="scenarios-ammount">
            <Poppins className="title" color="prussianBlue" weight={600} px={22}>
              {dataB.scenarios.length}
            </Poppins>
            <Poppins className="label" color="cflowerBlue" px={18}>
              scenario{dataB.scenarios.length ? 's' : ''}
            </Poppins>
          </div>
        </div>
      </div>
      <Spacer $px={30} />
      <div style={{ position: 'relative' }}>
        <HeadRow sort={sort} onSortSelect={setSort} />
        <Spacer $px={14} />

        {comparedScenarios.map((el, idx) => (
          <Row
            key={el.rowA?.id.value || '' + el.rowB?.id.value}
            dataA={el.rowA}
            dataB={el.rowB}
            noBorderTop={idx === 0}
            dataChanges={el.dataChanges}
            genPathInner={genPathInner}
          />
        ))}
      </div>
    </Div>
  );
};

export default ComparisonView;
