import React, { useCallback, useState } from 'react';
import { queryCache } from 'react-query';
import { GradientTextAction, InputLabel, Poppins, Spacer } from 'src/common';
import Button from 'src/components/form/Button';
import colors from 'src/theme/colors';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import { ValidationErrorItem, ControlCSV, ParseResult } from './types';
import { generateCsvTemplateLink, isValidHeaders, validateRow } from './util';
import { selectUserOption } from 'src/utils/misc';
import { useAuth } from 'src/state/auth';
import CSelect from 'src/components/form/Select';
import { Control, controllStatusOptions, strategyOptions } from 'src/api/types';
import { ControlCsvHeaders } from 'src/api/types/misc';
import { createControl } from 'src/api/controls';
import _ from 'lodash';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const Div = styled.div`
  .grid-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .grid-list {
      grid-gap: 10px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .grid-list {
      grid-template-columns: 1fr;
    }
  }
`;

const Dropzone = styled.div`
  border: 2px dashed ${colors.stroke};
  border-radius: 5px;
  text-align: center;
  padding: 70px 20px;
  cursor: pointer;
  background-color: #f6f6f9;
  &:hover {
    background-color: #f0f0f0;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 30px 10px;
  }
`;

const ErrorLog = styled.div`
  margin-top: 20px;
  max-height: 300px;
  overflow-y: auto;
  background-color: #ffeded;
  border: 1px solid #ffcccc;
  border-radius: 5px;
  color: ${colors.error};
  padding: 10px;
  display: grid;
  grid-gap: 5px;
`;

const ErrorItem = styled.div`
  padding: 5px;
  border-bottom: 1px solid #ffcfcf;
  &:last-child {
    border-bottom: none;
  }
`;

interface FromCsvProps {
  onClose: () => void;
  setIsScvDirty: (isDirty: boolean) => void;
  state: {
    file: File | null;
    setFile: (file: File | null) => void;
    readyForUpload: boolean;
    setReadyForUpload: (ready: boolean) => void;
  };
}

export const FromCsv: React.FC<FromCsvProps> = ({ onClose, setIsScvDirty, state }) => {
  const { usersOptios } = useAuth();
  const [error, setError] = useState('');
  const [isUploadSuccessful, setIsUploadSuccessful] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrorItem[]>([]);
  const [controlsProcessed, setControlsProcessed] = useState(0);
  const [totalControls, setTotalControls] = useState(0);
  const [validControls, setValidControls] = useState<Partial<Control>[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const { file, setFile, readyForUpload, setReadyForUpload } = state;

  const isButtonDisabled = !file || (controlsProcessed < totalControls && controlsProcessed > 0);
  const csvTemplateLink = generateCsvTemplateLink();

  const onDrop = useCallback((acceptedFiles) => {
    mpEvent(MPEvents.FileLoaded, {
      modal: 'Add RMP modal',
      tags: ['RMP'],
    });
    if (acceptedFiles && acceptedFiles.length > 0) {
      const selectedFile = acceptedFiles[0];
      setFile(selectedFile);
      setError('');
      setIsUploadSuccessful(false);
      setValidationErrors([]);
      parseCSVToJSON(selectedFile);
      setIsScvDirty(true);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'text/csv': ['.csv'],
    },
  });

  const parseCSVToJSON = (file: File) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: async (result: ParseResult<ControlCSV>) => {
        if (!result.data) {
          setError('No data found in the CSV file');
          return;
        }
        if (result.data.length === 0) {
          setValidationErrors([
            { rowIndex: 0, errors: [{ path: 'Data', message: 'Error: No control data to load.' }] },
          ]);
          return;
        }

        if (!isValidHeaders(result.data[0])) {
          setValidationErrors([
            { rowIndex: 0, errors: [{ path: 'Header', message: 'Invalid CSV template headers.' }] },
          ]);
          return;
        }

        if (result.data.length > 60) {
          setError('The number of controls exceeds the limit of 60.');
          return;
        }

        const mappedControls: Partial<Control>[] = result.data.map((el) => {
          return {
            id: el[ControlCsvHeaders.ID],
            name: el[ControlCsvHeaders.Name],
            strategy: el[ControlCsvHeaders.Strategy],
            status: el[ControlCsvHeaders.Status],
            owner: { text: el[ControlCsvHeaders.Owner] },
            annual_cost: el[ControlCsvHeaders.AnnualCost],
            vendor: el[ControlCsvHeaders.Vendor],
            expected_active_date: el[ControlCsvHeaders.ExpectedLiveDate],
            actual_active_date: el[ControlCsvHeaders.ActualLiveDate],
            review_date: el[ControlCsvHeaders.ReviewDate],
            description: el[ControlCsvHeaders.Description],
            frameworkLibrary_shortname: el[ControlCsvHeaders.Framework],
            controlId: el[ControlCsvHeaders.FrameworkID],
          };
        });

        // console.log('mappedControls', mappedControls);

        const promises = mappedControls.map((row, idx) => validateRow(row, idx + 1));

        Promise.all(promises).then((res) => {
          const errorLog = res.filter((el) => !!el?.errors.length) as ValidationErrorItem[];

          if (errorLog.length) {
            setValidationErrors(errorLog);
            setIsUploadSuccessful(false);
            setReadyForUpload(false);
          } else {
            setValidControls(mappedControls);
            setValidationErrors([]);
            setError('');
            setReadyForUpload(true);
          }
        });
      },

      error: (error: { message: string }) => {
        setValidationErrors([
          { rowIndex: 0, errors: [{ path: 'File', message: `CSV parsing error: ${error.message}` }] },
        ]);
      },
    });
  };

  const save = async () => {
    mpEvent(MPEvents.ButtonClick, {
      button: 'Upload',
      modal: 'Add RMP modal',
      tags: ['RMP'],
    });

    setTotalControls(validControls.length);
    setControlsProcessed(0);
    setReadyForUpload(false);
    setIsUploading(true);

    const requests = validControls.map((el) => {
      const { userAdd } = selectUserOption({
        usersOptios,
        selectedUser: { text: el.owner?.text || '' },
      });
      return createControl({ ...el, owner: userAdd }).finally(() => setControlsProcessed((prev) => prev + 1));
    });

    await Promise.all(requests).then(() => {
      queryCache.invalidateQueries();
      setIsUploading(false);
      setIsScvDirty(false);
    });

    if (controlsProcessed === totalControls) {
      setIsUploadSuccessful(controlsProcessed === totalControls);
      setReadyForUpload(false);
    }
  };

  const resetUpload = () => {
    setIsUploadSuccessful(false);
    setValidationErrors([]);
    setError('');
    setFile(null);
    setTotalControls(0);
    setControlsProcessed(0);
    setReadyForUpload(false);
  };

  return (
    <Div className="h-padding">
      <a href={csvTemplateLink} download="control_template.csv" style={{ textDecoration: 'none' }}>
        <GradientTextAction
          $underline
          onClick={() =>
            mpEvent(MPEvents.DownloadCSVTemplate, {
              modal: 'Add RMP modal',
              tags: ['RMP'],
            })
          }
        >
          Download CSV Template
        </GradientTextAction>
      </a>
      <Spacer $px={20} />
      {!isUploadSuccessful && (
        <Dropzone {...getRootProps()}>
          <input {...getInputProps()} />
          <Poppins px={14}>
            {isDragActive ? 'Drop the file here ...' : 'Drag and drop a file here, or click to select file'}
          </Poppins>
        </Dropzone>
      )}
      {!isUploadSuccessful && <Spacer $px={20} />}
      {file && (
        <>
          <Poppins px={14}>File selected: {file.name}</Poppins>
          <Spacer $px={15} />
        </>
      )}
      {file && validationErrors.length === 0 && (
        <>
          <Poppins px={14}>Controls ready for upload: {validControls.length}</Poppins>
          <Spacer $px={15} />
        </>
      )}
      {!isUploadSuccessful && controlsProcessed < totalControls && (
        <>
          <Poppins px={14}>{`Adding controls: ${controlsProcessed}/${totalControls}`}</Poppins>
          <Spacer $px={15} />
        </>
      )}
      {isUploadSuccessful && (
        <>
          <Poppins px={14}>File uploaded successfully!</Poppins>
          <Spacer $px={15} />
        </>
      )}
      {readyForUpload && !isUploadSuccessful && (
        <>
          <Poppins px={14}>Validation successful, ready for upload</Poppins>
          <Spacer $px={15} />
        </>
      )}
      {validationErrors.length > 0 && (
        <>
          <ErrorLog>
            {validationErrors.map((error, index) => (
              <ErrorItem key={index}>
                <Poppins color="error" px={16} weight={600}>
                  Error in row {error.rowIndex + 1}:
                </Poppins>
                {error.errors.map((e, idx) => (
                  <Poppins px={14} color="error" css="display: block" key={idx}>{`${e.path}: ${e.message}`}</Poppins>
                ))}
              </ErrorItem>
            ))}
          </ErrorLog>
          <Spacer $px={15} />
        </>
      )}
      {!isUploadSuccessful ? (
        <Button
          primary
          onClick={readyForUpload ? () => save() : resetUpload}
          css="width: 100%;"
          disabled={isButtonDisabled || !!validationErrors.length || isUploading}
        >
          {readyForUpload || isUploading ? 'Upload' : 'Select File'}
        </Button>
      ) : (
        <>
          <Button
            primary
            onClick={() => {
              resetUpload();
              setFile(null);
            }}
            css="width: 100%;"
          >
            Upload Another File
          </Button>
          <Spacer $px={20} />
          <Button primary onClick={onClose} css="width: 100%;">
            Done
          </Button>
        </>
      )}
      <Spacer $px={30} />
      {!file && (
        <div>
          <Poppins px={14}>The valid values of Strategy and Status are listed in the 2 drop down lists below.</Poppins>
          <Poppins css="display: block" px={14}>
            Blank is also valid for each.
          </Poppins>
          <Spacer $px={24} />

          <div className="grid-list">
            <div>
              <InputLabel>Strategy</InputLabel>
              <CSelect
                value={null}
                placeholder="Strategy"
                css="width: 100%;"
                menuPlacement="top"
                options={strategyOptions}
              />
            </div>
            <div>
              <InputLabel>Status</InputLabel>
              <CSelect
                value={null}
                placeholder="Status"
                css="width: 100%;"
                menuPlacement="top"
                options={controllStatusOptions}
              />
            </div>
          </div>
          <Spacer $px={20} />
        </div>
      )}
      {error && (
        <>
          <Spacer $px={20} />
          <div className="error">{error}</div>
        </>
      )}
    </Div>
  );
};
