import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { LSKEY_INITAIL_URL } from './utils/auth';
import * as Sentry from '@sentry/react';
import { isDev } from './config';

Sentry.init({
  dsn: 'https://6584a6c72ddf101e7499aa0fcf288fb0@o4507803976335360.ingest.de.sentry.io/4507815153565777',
  environment: isDev ? '[dev server]' : 'production',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserApiErrorsIntegration({
      setTimeout: true,
      setInterval: true,
      requestAnimationFrame: true,
      XMLHttpRequest: true,
      eventTarget: true,
    }),
    Sentry.httpClientIntegration({
      failedRequestStatusCodes: [
        400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414, 415, 416, 417, 418, 421, 426, 429,
        431, 451, 500, 502, 503, 504,
      ],
    }),
    Sentry.browserProfilingIntegration(),
  ],
  tracesSampleRate: 1.0,
  sendDefaultPii: true,
});

const exludedPaths = ['/reset-password', '/auth', '/callback', '/verify'];

(function () {
  const pathname = window.location.pathname;
  const search = window.location.search;

  if (!exludedPaths.includes(pathname) && pathname !== '/') {
    window.localStorage.setItem(LSKEY_INITAIL_URL, pathname + search);
  }
})();

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

const root = createRoot(MOUNT_NODE);

root.render(<App />);

if (module.hot) {
  module.hot.accept(['./App'], () => {
    const NextApp = require('./App').default;
    root.render(<NextApp />);
  });
}
