import { format, isValid } from 'date-fns';

export const currencyFormatter = (num: number) => {
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  let str = formatter.format(num || 0);

  if (num < 0) {
    str = '-' + str;
  }

  return str;
};

export const formatDate = (date: any) => {
  if (!date) return '-';
  return isValid(new Date(date)) ? format(new Date(date), 'dd-MM-yyyy') : '-';
};

export const getExternalPath = (path: string) => {
  return `${window.location.origin}/${path}`;
};
