import React from 'react';
import colors from 'src/theme/colors';
import styled from 'styled-components';
import { ReviewDate } from './ReviewDate';
import { Poppins, Spacer } from 'src/common';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const Div = styled.div`
  /* border: 1px solid red; */
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .legend {
    display: flex;
    align-items: center;
    grid-gap: 15px;
    &__item {
      display: flex;
      align-items: center;
      grid-gap: 8px;
      user-select: none;
      cursor: pointer;
      margin-bottom: 11px;

      &__title {
        margin-right: 10px;
      }
    }
  }

  .dates {
    display: flex;
    grid-gap: 20px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: flex-start;
    .legend {
      grid-gap: 10px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .legend {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      grid-row-gap: 2px;
      &__item {
        margin-bottom: 0;
      }
    }
    .dates {
      grid-gap: 10px;
    }
  }
`;

interface BottomDataProps {
  nameA: string;
  nameB: string;
  dateA: string;
  dateB: string;
  onSetSeriesA: () => void;
  onSetSeriesB: () => void;
  onSetSeriesARisk: () => void;
  onSetSeriesBRisk: () => void;
}

export const BottomData: React.FC<BottomDataProps> = ({
  nameA,
  nameB,
  dateA,
  dateB,
  onSetSeriesA,
  onSetSeriesB,
  onSetSeriesARisk,
  onSetSeriesBRisk,
}) => {
  return (
    <Div>
      <div className="legend">
        <div
          className="legend__item"
          onClick={() => {
            onSetSeriesA();
            mpEvent(MPEvents.GraphLegendToggle, { value: 'Loss Exceedance: A' });
          }}
        >
          <svg width="29" height="4" viewBox="0 0 29 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2H26.5" stroke={colors.red} strokeWidth="4" strokeLinecap="round" />
          </svg>
          <Poppins className="legend__item__title" px={14} weight={500} color="cflowerBlue">
            {nameA}
          </Poppins>
        </div>

        <div
          className="legend__item"
          onClick={() => {
            onSetSeriesB();
            mpEvent(MPEvents.GraphLegendToggle, { value: 'Loss Exceedance: B' });
          }}
        >
          <svg width="29" height="4" viewBox="0 0 29 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2H26.5" stroke={colors.compareBLine} strokeWidth="4" strokeLinecap="round" />
          </svg>
          <Poppins className="legend__item__title" px={14} weight={500} color="cflowerBlue">
            {nameB}
          </Poppins>
        </div>

        <div
          className="legend__item"
          onClick={() => {
            onSetSeriesARisk();
            mpEvent(MPEvents.GraphLegendToggle, { value: 'Risk Tolerance: A' });
          }}
        >
          <svg width="29" height="4" viewBox="0 0 29 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2H26.5" stroke={colors.red} strokeWidth="4" strokeLinecap="round" strokeDasharray="8 8" />
          </svg>
          <Poppins className="legend__item__title" px={14} weight={500} color="cflowerBlue">
            Risk Tolerance
          </Poppins>
        </div>

        <div
          className="legend__item"
          onClick={() => {
            onSetSeriesBRisk();
            mpEvent(MPEvents.GraphLegendToggle, { value: 'Risk Tolerance: B' });
          }}
        >
          <svg width="29" height="4" viewBox="0 0 29 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2 2H26.5"
              stroke={colors.compareBLine}
              strokeWidth="4"
              strokeLinecap="round"
              strokeDasharray="8 8"
            />
          </svg>
          <Poppins className="legend__item__title" px={14} weight={500} color="cflowerBlue">
            Risk Tolerance
          </Poppins>
        </div>
      </div>

      <div className="dates">
        <div>
          <Poppins weight={500} color="cflowerBlue" px={14}>
            {nameA}
          </Poppins>
          <Spacer $px={5} />
          <ReviewDate date={dateA} />
        </div>
        <div>
          <Poppins weight={500} color="cflowerBlue" px={14}>
            {nameB}
          </Poppins>
          <Spacer $px={5} />
          <ReviewDate date={dateB} />
        </div>
      </div>
    </Div>
  );
};
