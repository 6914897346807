import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getAssessment, getAssessmentVersions } from 'src/api/assessment';
import Meta from 'src/components/Meta';
import { PageChartSkeleton, PageContainer, Spacer } from 'src/common';
import ScenariosCompare from './comps/scenarios/ScenariosCompare';
import { useGaEventPageView } from 'src/utils/gaEvent';
import { Charts } from './comps/charts';
import { AssessmentVersion, QKeys } from 'src/api/types';
import useVersions from '../useVersions';

const CompareAssessment = () => {
  const { idA, idB } = useParams<{ idA: string; idB: string }>();
  const { versionAId, versionBId } = useVersions();
  const [versionA, setVersionA] = useState<AssessmentVersion | undefined>(undefined);
  const [versionB, setVersionB] = useState<AssessmentVersion | undefined>(undefined);

  const { data: dataA, isLoading: ilda } = useQuery(
    [QKeys.Assessment, { assessmentId: idA, version: versionAId }],
    getAssessment,
  );

  const { data: dataB, isLoading: ildb } = useQuery(
    [QKeys.Assessment, { assessmentId: idB, version: versionBId }],
    getAssessment,
  );

  const { data: versionsA, isLoading: ilva } = useQuery([QKeys.Versions, idA], () =>
    getAssessmentVersions(idA as string),
  );
  const { data: versionsB, isLoading: ilvb } = useQuery([QKeys.Versions, idB], () =>
    getAssessmentVersions(idB as string),
  );

  useEffect(() => {
    versionsA && setVersionA(versionsA.versions.find((v) => v.id === versionAId));
    versionsB && setVersionB(versionsB.versions.find((v) => v.id === versionBId));
  }, [versionAId, versionBId, versionsA, versionsB]);

  useGaEventPageView('Cydea | Compare Assessments');

  const isReady = !ilda && !ildb && !ilva && !ilvb;

  return (
    <>
      <Meta title={`Cydea | Compare Assessments`} feedbackScript />
      <PageContainer>
        {dataA && dataB && isReady ? (
          <div data-cy="compare-assessments-page">
            <Charts dataA={dataA} dataB={dataB} versionA={versionA} versionB={versionB} />
            <Spacer $px={60} />
            <ScenariosCompare dataA={dataA} dataB={dataB} versionA={versionA} versionB={versionB} />
          </div>
        ) : (
          <PageChartSkeleton />
        )}
      </PageContainer>
    </>
  );
};

export default CompareAssessment;
