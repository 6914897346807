import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { Control, QKeys, Scenario } from 'src/api/types';
import { GTColumnSelectAnchor, GTExpander, GTModifierLabel, GTTdm } from 'src/components/GenericTable';
import { formatDate, formatNumber } from 'src/utils/misc';
import { filter } from 'lodash';
import { Link } from 'react-router-dom';
import { GTInternalIds } from 'src/components/GenericTable/types';
import _ from 'lodash';
import { unlinkScenarioFromControl } from 'src/api/plan';
import { queryCache, useMutation } from 'react-query';
import { IncompleteStateMarker, getIncompleteState } from 'src/common';
import { MPEvents } from 'src/utils/mixpanel/types';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import useVersion from 'src/hooks/useVersion';
import { encodeQueryParams } from 'src/api/client';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TableIds } from 'src/api/types/misc';

type UseColumns = (a: { data?: Control[]; scenario: Scenario }) => {
  columns: ColumnDef<Control>[];
  GTColumnSelectAnchorExported: JSX.Element;
};

const defaultColumns = [
  'strategy',
  'name',
  'owner',
  'frameworkLibrary_shortname',
  'controlId',
  'status',
  'expected_active_date',
  'actual_active_date',
  'updated_at',
  '_mod_applied',
];

export const useColumnsControls: UseColumns = ({ data = [], scenario }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(`${TableIds.scenarioControls}-columns`, defaultColumns);
  const { version, assessmentId } = useVersion();

  const resetCache = async () => {
    await queryCache.invalidateQueries([QKeys.ScenarioControls, scenario.id, { version }]);
    await queryCache.invalidateQueries([QKeys.Scenario, { scenarioId: scenario.id }]);
  };

  const [unlink] = useMutation(unlinkScenarioFromControl, {
    onSuccess: resetCache,
  });

  const columns = useMemo(() => {
    const internalIds = [GTInternalIds.expander, GTInternalIds.tdm, GTInternalIds.checker];

    const list: ColumnDef<Control>[] = [
      {
        id: GTInternalIds.expander,
        header: () => null,
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <GTExpander onClick={row.getToggleExpandedHandler()} isExpanded={row.getIsExpanded()} />
          ) : null;
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: 'strategy',
        header: 'Strategy',
        accessorFn: (row) => row.strategy || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => row.name,
        cell: (info) => (
          <Link
            className="table-link"
            to={`/risk-management/control/${info.row.original.id}${
              version ? encodeQueryParams({ version, assessmentId }) : ''
            }`}
            onClick={() =>
              mpEvent(MPEvents.Link, {
                source: { value: ['Scenario'], params: { id: scenario.id } },
                destination: { value: ['Control'], params: { id: info.row.original.id } },
                tags: ['SCENARIO', 'RMP'],
              })
            }
          >
            {info.getValue()}
          </Link>
        ),
        sortingFn: sortingFns.text,
        maxSize: 300,
      },
      {
        id: 'owner',
        header: 'Owner',
        accessorFn: (row) => row.owner?.text || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },
      {
        id: 'frameworkLibrary_shortname',
        header: 'Framework',
        accessorFn: (row) => row.frameworkLibrary_shortname || null,
        cell: (info) => (
          <Link
            className="table-link"
            to={`/control-frameworks/${info.row.original.frameworkLibrary_ux_id}`}
            onClick={() =>
              mpEvent(MPEvents.Link, {
                source: { value: ['Scenario'], params: { id: scenario.id } },
                destination: { value: ['Framework'], params: { id: info.row.original.frameworkLibrary_ux_id } },
                tags: ['SCENARIO', 'FRAMEWORK'],
              })
            }
          >
            {info.getValue()}
          </Link>
        ),
        sortingFn: sortingFns.text,
      },
      {
        id: 'controlId',
        header: 'Framework ID',
        accessorFn: (row) => row.controlId || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'annual_cost',
        header: 'Control Cost',
        accessorFn: (row) => row.annual_cost || null,
        cell: ({ row }) => (row.original.annual_cost === null ? '' : `£${formatNumber(row.original.annual_cost)}`),
        sortingFn: sortingFns.alphanumeric,
      },
      {
        id: 'status',
        header: 'Status',
        accessorFn: (row) => row.status || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'expected_active_date',
        header: 'Expected Live Date',
        accessorFn: (row) => row.expected_active_date || null,
        cell: ({ row }) => formatDate(row.original.expected_active_date),
      },
      {
        id: 'actual_active_date',
        header: 'Actual Live Date',
        accessorFn: (row) => row.actual_active_date || null,
        cell: ({ row }) => formatDate(row.original.actual_active_date),
      },
      {
        id: 'updated_at',
        header: 'Last Edited',
        accessorFn: (row) => row.updated_at || null,
        cell: ({ row }) => formatDate(row.original.updated_at),
      },
      {
        id: '_mod_applied',
        header: 'Modifiers',
        accessorFn: (row) => (row.modifiers?.treat.length || 0) + (row.modifiers?.transfer.length || 0),
        cell: (info) => {
          const modifiersAvailable =
            info.row.original.strategy === 'Treat' || info.row.original.strategy === 'Transfer';
          if (!modifiersAvailable) return null;
          return (
            <GTModifierLabel
              length={info.getValue() as number}
              satisfiesModifier={getIncompleteState(scenario) !== 'TECH'}
            />
          );
        },
      },
      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: ({ row }) => {
          const onUnlickControl = !version
            ? () => {
                // @ts-ignore
                const planId = // @ts-ignore
                  scenario.implementationPlans.find((el) => el.control?.ux_id === row.original.id)?.ux_id;
                unlink(planId);
              }
            : undefined;
          return (
            <div css="display: flex;grid-gap: 8px">
              <GTTdm
                extraComponentLeft={<IncompleteStateMarker scenario={scenario} />}
                onUnlickControl={onUnlickControl}
              />
            </div>
          );
        },
        size: 1,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns, scenario, version, assessmentId]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'strategy', title: 'Strategy' },
        { key: 'name', title: 'Name' },
        { key: 'owner', title: 'Owner' },
        { key: 'frameworkLibrary_shortname', title: 'Framework' },
        { key: 'controlId', title: 'Framework ID' },
        { key: 'annual_cost', title: 'Control Cost' },
        { key: 'status', title: 'Status' },
        { key: 'expected_active_date', title: 'Expected Live Date' },
        { key: 'actual_active_date', title: 'Actual Live Date' },
        { key: 'updated_at', title: 'Last Edited' },
        { key: '_mod_applied', title: 'Modifiers' },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return {
    columns,
    GTColumnSelectAnchorExported,
  };
};
