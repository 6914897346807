import React from 'react';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import { PageContainer, Poppins } from 'src/common';
import Audit from 'src/components/Audit';
import { useAuth } from 'src/state/auth';
import { TableIds } from 'src/api/types/misc';

const WorkspaceAuditPage = () => {
  const { activeWorkspace, userFeatures } = useAuth();
  useGaEventPageView('Cydea | Workspace | Audit');

  if (!userFeatures.app.audit.report) return null;

  return (
    <>
      <Meta title={`Cydea | Workspace | Audit`} feedbackScript />
      <PageContainer>
        <Poppins px={32} weight={600}>
          Audit Log
        </Poppins>
        <Audit noHide maxHeight={700} query={{ workspace: activeWorkspace?.id }} tableId={TableIds.auditWorkspace} />
      </PageContainer>
    </>
  );
};

export default WorkspaceAuditPage;
