import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { GTColumnSelectAnchor, GTExpander, GTModifierLabel } from 'src/components/GenericTable';
import { formatDate, formatNumber } from 'src/utils/misc';
import { filter } from 'lodash';
import { Link } from 'react-router-dom';
import { GTInternalIds } from 'src/components/GenericTable/types';
import _ from 'lodash';
import { ControlStateItem } from 'src/components/ProjectedRisk/types';
import Input from 'src/components/form/Input';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import useVersion from 'src/hooks/useVersion';
import { encodeQueryParams } from 'src/api/client';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TableIds } from 'src/api/types/misc';

type UseColumns = (a: {
  data?: ControlStateItem[];
  scenarioId: string;
  assessmentId: string;
  toggleControl: (isEnabled: boolean, id: string) => Promise<void>;
}) => {
  columns: ColumnDef<ControlStateItem>[];
  GTColumnSelectAnchorExported: JSX.Element;
};

const defaultColumns = [
  'strategy',
  'name',
  'owner',
  'status',
  'expected_active_date',
  '_mod_applied',
  '_projected_status',
];

export const useColumnsControls: UseColumns = ({ data = [], scenarioId, assessmentId, toggleControl }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(
    `${TableIds.prScenarioScenarios}-columns`,
    defaultColumns,
  );
  const { version } = useVersion();

  const columns = useMemo(() => {
    const internalIds = [GTInternalIds.expander, GTInternalIds.tdm, GTInternalIds.checker];

    const list: ColumnDef<ControlStateItem>[] = [
      {
        id: GTInternalIds.expander,
        header: () => null,
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <GTExpander onClick={row.getToggleExpandedHandler()} isExpanded={row.getIsExpanded()} />
          ) : null;
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: 'strategy',
        header: 'Strategy',
        accessorFn: (row) => row.control.strategy || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => row.control.name,
        cell: (info) => (
          <Link
            className="table-link"
            to={`/risk-management/control/${info.row.original.control.id}${encodeQueryParams({
              version,
              assessmentId,
            })}`}
          >
            {info.getValue()}
          </Link>
        ),
        sortingFn: sortingFns.text,
        maxSize: 300,
      },
      {
        id: 'owner',
        header: 'Owner',
        accessorFn: (row) => row.control.owner?.text || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },
      {
        id: 'frameworkLibrary_shortname',
        header: 'Framework',
        accessorFn: (row) => row.control.frameworkLibrary_shortname || null,
        cell: (info) => (
          <Link className="table-link" to={`/control-frameworks/${info.row.original.control.frameworkLibrary_ux_id}`}>
            {info.getValue()}
          </Link>
        ),
        sortingFn: sortingFns.text,
      },
      {
        id: 'controlId',
        header: 'Framework ID',
        accessorFn: (row) => row.control.controlId || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'annual_cost',
        header: 'Control Cost',
        accessorFn: (row) => row.control.annual_cost || null,
        cell: ({ row }) =>
          row.original.control.annual_cost === null ? '' : `£${formatNumber(row.original.control.annual_cost)}`,
        sortingFn: sortingFns.alphanumeric,
      },
      {
        id: 'status',
        header: 'Status',
        accessorFn: (row) => row.control.status || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'updated_at',
        header: 'Last Edited',
        accessorFn: (row) => row.control.updated_at || null,
        cell: ({ row }) => formatDate(row.original.control.updated_at),
      },
      {
        id: 'expected_active_date',
        header: 'Expected Live Date',
        accessorFn: (row) => row.control.expected_active_date || null,
        cell: ({ row }) => formatDate(row.original.control.expected_active_date),
        maxSize: 180,
      },
      {
        id: '_mod_applied',
        header: 'Modifiers',
        accessorFn: (row) => (row.control.modifiers?.treat.length || 0) + (row.control.modifiers?.transfer.length || 0),
        cell: (info) => {
          const satisfiesModifier = true;
          const modifiersAvailable =
            info.row.original.control.strategy === 'Treat' || info.row.original.control.strategy === 'Transfer';
          if (!modifiersAvailable) return null;
          return <GTModifierLabel length={info.getValue() as number} satisfiesModifier={satisfiesModifier} />;
        },
      },
      {
        id: '_projected_status',
        header: 'Projected Status',
        accessorFn: (row) => row.control.expected_active_date || null,
        cell: ({ row }) => {
          if (row.original.control.strategy !== 'Treat' && row.original.control.strategy !== 'Transfer') {
            return null;
          }
          return (
            <Input
              onClick={(e) => e.stopPropagation()}
              checked={row.original.isEnabled}
              onChange={(e) => {
                toggleControl(e.target.checked, row.original.control.id);
                mpEvent(MPEvents.ButtonClick, {
                  button: '[projected status toggle]',
                  modal: 'Projected risk:Scenario modal',
                  tags: ['SCENARIO', 'MODIFIERS'],
                });
              }}
              className="switch"
              type="checkbox"
              disabled={false}
            />
          );
        },
        maxSize: 180,
      },
      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: () => null,
        size: 1,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns, scenarioId, version, assessmentId]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'strategy', title: 'Strategy' },
        { key: 'name', title: 'Name' },
        { key: 'owner', title: 'Owner' },
        { key: 'frameworkLibrary_shortname', title: 'Framework' },
        { key: 'controlId', title: 'Framework ID' },
        { key: 'annual_cost', title: 'Control Cost' },
        { key: 'status', title: 'Status' },
        { key: 'updated_at', title: 'Last Edited' },
        { key: 'expected_active_date', title: 'Expected Live Date' },
        { key: '_mod_applied', title: 'Modifiers' },
        { key: '_projected_status', title: 'Projected Status' },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return {
    columns,
    GTColumnSelectAnchorExported,
  };
};
