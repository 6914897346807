import { Control } from 'src/api/types';
import { ControlCsvHeaders } from 'src/api/types/misc';
import * as Yup from 'yup';
import { rmpValidationSchema } from 'src/components/formComposed/RmpForm/util';
import _ from 'lodash';
import { ControlCSV } from './types';

export const generateCsvTemplateLink = () => {
  const csvString = Object.values(ControlCsvHeaders).join(',') + '\n';

  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  return URL.createObjectURL(blob);
};

const pathNames = {
  id: ControlCsvHeaders.ID,
  name: ControlCsvHeaders.Name,
  strategy: ControlCsvHeaders.Strategy,
  status: ControlCsvHeaders.Status,
  owner: ControlCsvHeaders.Owner,
  annual_cost: ControlCsvHeaders.AnnualCost,
  vendor: ControlCsvHeaders.Vendor,
  expected_active_date: ControlCsvHeaders.ExpectedLiveDate,
  actual_active_date: ControlCsvHeaders.ActualLiveDate,
  review_date: ControlCsvHeaders.ReviewDate,
  description: ControlCsvHeaders.Description,
  frameworkLibrary_shortname: ControlCsvHeaders.Framework,
  controlId: ControlCsvHeaders.FrameworkID,
};

export const validateRow = async (row: Partial<Control>, rowIndex: number) => {
  try {
    await rmpValidationSchema.validate(row, { abortEarly: false });
    return null;
  } catch (err) {
    console.log({ err, row });
    if (err instanceof Yup.ValidationError) {
      const customErrors = err.inner.map((e) => {
        return { path: _.get(pathNames, e.path), message: e.message };
      });
      return { rowIndex, errors: customErrors };
    } else {
      return {
        rowIndex,
        errors: [{ path: 'Unexpected Error', message: 'An unexpected error occurred during validation.' }],
      };
    }
  }
};

export const isValidHeaders = (data: ControlCSV) => {
  const expectedHeaders = Object.values(ControlCsvHeaders).map(String);
  const parsedHeaders = Object.keys(data);

  const missingElements = _.difference(expectedHeaders, parsedHeaders);

  return missingElements.length === 0;
};
