import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { AuditItem } from 'src/api/types';
import { GTColumnSelectAnchor, GTExpander, GTTdm } from 'src/components/GenericTable';
import { formatDate } from 'src/utils/misc';
import { filter } from 'lodash';
import { GTInternalIds } from 'src/components/GenericTable/types';
import _ from 'lodash';
import { TableUserItem } from 'src/common';
import { TableIds } from 'src/api/types/misc';
import useLocalStorage from 'src/hooks/useLocalStorage';

type UseColumns = (a: { data?: AuditItem[]; tableId: TableIds }) => {
  columns: ColumnDef<AuditItem>[];
  GTColumnSelectAnchorExported: JSX.Element;
};

const defaultColumns = ['user_name', 'name', 'action', 'day'];

export const useAuditColumns: UseColumns = ({ data = [], tableId }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(`${tableId}-columns`, defaultColumns);

  const columns = useMemo(() => {
    const internalIds = [GTInternalIds.expander, GTInternalIds.tdm];

    const list: ColumnDef<AuditItem>[] = [
      {
        id: GTInternalIds.expander,
        header: () => null,
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <GTExpander onClick={row.getToggleExpandedHandler()} isExpanded={row.getIsExpanded()} />
          ) : null;
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: 'user_name',
        header: 'User',
        accessorFn: (row) => row.user_name || null,
        cell: ({ row }) => (
          <TableUserItem name={row.original.user_name} profilePhotoPath={row.original.profile_photo_path} />
        ),
        sortingFn: sortingFns.text,
        enableSorting: false,
        size: 100,
        maxSize: 200,
      },
      {
        id: 'name',
        header: 'Item',
        accessorFn: (row) => row.name || null,
        cell: (info) => info.getValue(),
        enableSorting: false,
        size: 100,
        maxSize: 200,
      },
      {
        id: 'action',
        header: 'Action',
        accessorFn: (row) => row.action_title || null,
        cell: (info) => info.getValue(),
        enableSorting: false,
        size: 200,
        maxSize: 400,
      },

      {
        id: 'day',
        header: 'Date',
        accessorFn: (row) => row.date_time || null,
        cell: ({ row }) => row.original.date_time,
        size: 100,
        maxSize: 200,
      },

      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: () => {
          return (
            <div css="display: flex;grid-gap: 8px">
              <GTTdm />
            </div>
          );
        },
        size: 1,
        maxSize: 20,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'user_name', title: 'User' },
        { key: 'name', title: 'Item' },
        { key: 'action', title: 'Action' },
        { key: 'day', title: 'Date' },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return {
    columns,
    GTColumnSelectAnchorExported,
  };
};
