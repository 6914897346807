const colors = {
  gradient: 'linear-gradient(106.11deg, #E62E78 0.01%, #FD5630 98.53%)',
  gradientVertical: 'linear-gradient(180deg, #E62E78 0.01%, #FD5630 98.53%)',
  gradientBlue: 'linear-gradient(150.75deg, #09C1F4 -10.2%, #6A7590 121.37%)',
  gradientPart: '#FD5630',
  red: '#E52D7A', //'#E40045',
  hoverGradient: 'linear-gradient(106.11deg, #F26CA2 0.01%, #F97253 98.53%)',
  prussianBlue: '#022033',
  davysGrey: '#555862',
  aliceBlue: '#D5E7F2',
  brightBlue: '#2BBDEF',
  cultured: '#F6F6F9',
  stroke: '#E9ECFF',
  cflowerBlue: '#6A7590',
  white: '#FFFFFF',
  warning: '#FF5A28',
  error: '#E32121',
  compareAFill: 'rgba(228, 0, 69, 0.2)',
  compareBLine: '#6B78CD',
  compareBFill: 'rgba(107, 120, 205,0.2)',
  success: '#4caf50',
};

export default colors;
