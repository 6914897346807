import {
  ControllStatus,
  IncidentType,
  Plan,
  RiskConsequences,
  RiskEvents,
  RiskSources,
  SortType,
  Strategy,
  UserRole,
  WorkspaceType,
} from './types';

export const riskSourcesNames = {
  [RiskSources.Disgruntled]: 'Disgruntled',
  [RiskSources.Accidental]: 'Accidental',
  [RiskSources.Ineffective]: 'Ineffective',
  [RiskSources.Criminal]: 'Criminal',
  [RiskSources.Coerced]: 'Coerced',
  [RiskSources.Criminals]: 'Criminals',
  [RiskSources.Hacktivists]: 'Hacktivists',
  [RiskSources.Compromised]: 'Compromised suppliers',
  [RiskSources.StateSponsored]: 'State-Sponsored',
  [RiskSources.Competitor]: 'Competitor',
  [RiskSources.Press]: 'Press',
  [RiskSources.Researcher]: 'Researcher',
  [RiskSources.Regulator]: 'Regulator',
};

export const riskSourcesOptions = [
  {
    label: 'Internal',
    options: [
      { label: riskSourcesNames[RiskSources.Disgruntled], value: RiskSources.Disgruntled },
      { label: riskSourcesNames[RiskSources.Accidental], value: RiskSources.Accidental },
      { label: riskSourcesNames[RiskSources.Ineffective], value: RiskSources.Ineffective },
      { label: riskSourcesNames[RiskSources.Criminal], value: RiskSources.Criminal },
      { label: riskSourcesNames[RiskSources.Coerced], value: RiskSources.Coerced },
    ],
  },
  {
    label: 'External',
    options: [
      { label: riskSourcesNames[RiskSources.Criminals], value: RiskSources.Criminals },
      { label: riskSourcesNames[RiskSources.Hacktivists], value: RiskSources.Hacktivists },
      { label: riskSourcesNames[RiskSources.Compromised], value: RiskSources.Compromised },
      { label: riskSourcesNames[RiskSources.StateSponsored], value: RiskSources.StateSponsored },
      { label: riskSourcesNames[RiskSources.Competitor], value: RiskSources.Competitor },
      { label: riskSourcesNames[RiskSources.Press], value: RiskSources.Press },
      { label: riskSourcesNames[RiskSources.Researcher], value: RiskSources.Researcher },
      { label: riskSourcesNames[RiskSources.Regulator], value: RiskSources.Regulator },
    ],
  },
];

export const riskEventsNames = {
  [RiskEvents.ServiceUnavailability]: 'Service Unavailability',
  [RiskEvents.ServiceCompromise]: 'Service Compromise',
  [RiskEvents.InformationBreach]: 'Information Breach',
  [RiskEvents.AccessToOurSystemBreach]: 'Access to Our System Breach',
  [RiskEvents.ComplianceFailure]: 'Compliance Failure',
  [RiskEvents.RulesChange]: 'Rules Change',
  [RiskEvents.CriticalVulnerabilityPublished]: 'Critical Vulnerability Published',
  [RiskEvents.ChildSexualViolentContent]: 'Child / Sexual / Violent Content',
  [RiskEvents.HarmfulSpeech]: 'Harmful Speech',
  [RiskEvents.Harassment]: 'Harassment',
  [RiskEvents.Ransomware]: 'Ransomware',
  [RiskEvents.Worm]: 'Worm',
  [RiskEvents.Spyware]: 'Spyware',
  [RiskEvents.Rootkit]: 'Rootkit',
  [RiskEvents.Dialler]: 'Dialler',
  [RiskEvents.Sabotage]: 'Sabotage',
  [RiskEvents.DistributedDenialOfService]: 'Distributed / Denial of Service',
  [RiskEvents.OpenSourceIntelligenceAnalysis]: 'Open Source Intelligence Analysis',
  [RiskEvents.NetworkScanning]: 'Network Scanning',
  [RiskEvents.NetworkSniffing]: 'Network Sniffing',
  [RiskEvents.Lies]: 'Lies',
  [RiskEvents.Threats]: 'Threats',
  [RiskEvents.Phishing]: 'Phishing',
  [RiskEvents.Bribes]: 'Bribes',
  [RiskEvents.UnauthorisedAccessToInformation]: 'Unauthorised access to information',
  [RiskEvents.UnauthorisedSharingOfInformation]: 'Unauthorised sharing of information',
  [RiskEvents.UnauthorisedModificationOfInformation]: 'Unauthorised modification of information',
  [RiskEvents.UnauthorisedDeletionOfInformation]: 'Unauthorised deletion of information',
  [RiskEvents.UnauthorisedAccessToSystemComponent]: 'Unauthorised access to system / component',
  [RiskEvents.MisappropriationMisuseOfResources]: 'Misappropriation / misuse of resources',
  [RiskEvents.FalseRepresentation]: 'False representation',
  [RiskEvents.TheftOfMoney]: 'Theft of money',
  [RiskEvents.CrossSiteScripting]: 'Cross-site scripting (XSS)',
  [RiskEvents.FileInclusion]: 'File Inclusion',
  [RiskEvents.ControlSystemBypass]: 'Control System Bypass',
  [RiskEvents.UseOfStolenCredentials]: 'Use of stolen credentials',
  [RiskEvents.PasswordBruteForce]: 'Password brute force',
  [RiskEvents.SoftwareExploit]: 'Software Exploit',
  [RiskEvents.SQLInjection]: 'SQL injection',
  [RiskEvents.ProcessFailure]: 'Process failure',
  [RiskEvents.AuditFailure]: 'Audit Failure',
};

export const riskEventsOptions = [
  {
    label: 'Supplier',
    options: [
      { label: riskEventsNames[RiskEvents.ServiceUnavailability], value: RiskEvents.ServiceUnavailability },
      { label: riskEventsNames[RiskEvents.ServiceCompromise], value: RiskEvents.ServiceCompromise },
      { label: riskEventsNames[RiskEvents.InformationBreach], value: RiskEvents.InformationBreach },
      { label: riskEventsNames[RiskEvents.AccessToOurSystemBreach], value: RiskEvents.AccessToOurSystemBreach },
      { label: riskEventsNames[RiskEvents.ComplianceFailure], value: RiskEvents.ComplianceFailure },
    ],
  },
  {
    label: 'Regulatory',
    options: [{ label: riskEventsNames[RiskEvents.RulesChange], value: RiskEvents.RulesChange }],
  },
  {
    label: 'Research',
    options: [
      {
        label: riskEventsNames[RiskEvents.CriticalVulnerabilityPublished],
        value: RiskEvents.CriticalVulnerabilityPublished,
      },
    ],
  },
  {
    label: 'Abusive Content',
    options: [
      { label: riskEventsNames[RiskEvents.HarmfulSpeech], value: RiskEvents.HarmfulSpeech },
      { label: riskEventsNames[RiskEvents.ChildSexualViolentContent], value: RiskEvents.ChildSexualViolentContent },
      { label: riskEventsNames[RiskEvents.Harassment], value: RiskEvents.Harassment },
    ],
  },
  {
    label: 'Malware',
    options: [
      { label: riskEventsNames[RiskEvents.Ransomware], value: RiskEvents.Ransomware },
      { label: riskEventsNames[RiskEvents.Worm], value: RiskEvents.Worm },
      { label: riskEventsNames[RiskEvents.Spyware], value: RiskEvents.Spyware },
      { label: riskEventsNames[RiskEvents.Rootkit], value: RiskEvents.Rootkit },
      { label: riskEventsNames[RiskEvents.Dialler], value: RiskEvents.Dialler },
    ],
  },
  {
    label: 'Availability Interruption',
    options: [
      { label: riskEventsNames[RiskEvents.DistributedDenialOfService], value: RiskEvents.DistributedDenialOfService },
      { label: riskEventsNames[RiskEvents.Sabotage], value: RiskEvents.Sabotage },
    ],
  },
  {
    label: 'Information Gathering',
    options: [
      {
        label: riskEventsNames[RiskEvents.OpenSourceIntelligenceAnalysis],
        value: RiskEvents.OpenSourceIntelligenceAnalysis,
      },
      { label: riskEventsNames[RiskEvents.NetworkScanning], value: RiskEvents.NetworkScanning },
      { label: riskEventsNames[RiskEvents.NetworkSniffing], value: RiskEvents.NetworkSniffing },
    ],
  },
  {
    label: 'Social Engineering',
    options: [
      { label: riskEventsNames[RiskEvents.Lies], value: RiskEvents.Lies },
      { label: riskEventsNames[RiskEvents.Threats], value: RiskEvents.Threats },
      { label: riskEventsNames[RiskEvents.Phishing], value: RiskEvents.Phishing },
      { label: riskEventsNames[RiskEvents.Bribes], value: RiskEvents.Bribes },
    ],
  },
  {
    label: 'Information Breach',
    options: [
      {
        label: riskEventsNames[RiskEvents.UnauthorisedAccessToSystemComponent],
        value: RiskEvents.UnauthorisedAccessToSystemComponent,
      },
      {
        label: riskEventsNames[RiskEvents.UnauthorisedAccessToInformation],
        value: RiskEvents.UnauthorisedAccessToInformation,
      },
      {
        label: riskEventsNames[RiskEvents.UnauthorisedSharingOfInformation],
        value: RiskEvents.UnauthorisedSharingOfInformation,
      },
      {
        label: riskEventsNames[RiskEvents.UnauthorisedModificationOfInformation],
        value: RiskEvents.UnauthorisedModificationOfInformation,
      },
      {
        label: riskEventsNames[RiskEvents.UnauthorisedDeletionOfInformation],
        value: RiskEvents.UnauthorisedDeletionOfInformation,
      },
    ],
  },
  {
    label: 'Fraud',
    options: [
      {
        label: riskEventsNames[RiskEvents.MisappropriationMisuseOfResources],
        value: RiskEvents.MisappropriationMisuseOfResources,
      },
      { label: riskEventsNames[RiskEvents.FalseRepresentation], value: RiskEvents.FalseRepresentation },
      { label: riskEventsNames[RiskEvents.TheftOfMoney], value: RiskEvents.TheftOfMoney },
    ],
  },
  {
    label: 'System Intrusion',
    options: [
      { label: riskEventsNames[RiskEvents.SoftwareExploit], value: RiskEvents.SoftwareExploit },
      { label: riskEventsNames[RiskEvents.SQLInjection], value: RiskEvents.SQLInjection },
      { label: riskEventsNames[RiskEvents.CrossSiteScripting], value: RiskEvents.CrossSiteScripting },
      { label: riskEventsNames[RiskEvents.FileInclusion], value: RiskEvents.FileInclusion },
      { label: riskEventsNames[RiskEvents.ControlSystemBypass], value: RiskEvents.ControlSystemBypass },
      { label: riskEventsNames[RiskEvents.UseOfStolenCredentials], value: RiskEvents.UseOfStolenCredentials },
      { label: riskEventsNames[RiskEvents.PasswordBruteForce], value: RiskEvents.PasswordBruteForce },
    ],
  },
  {
    label: 'Governance Failure',
    options: [
      { label: riskEventsNames[RiskEvents.ProcessFailure], value: RiskEvents.ProcessFailure },
      { label: riskEventsNames[RiskEvents.AuditFailure], value: RiskEvents.AuditFailure },
    ],
  },
];

export const riskConsequencesNames = {
  [RiskConsequences.ReducedGrowth]: 'Reduced growth',
  [RiskConsequences.BusinessDisruption]: 'Business Disruption',
  [RiskConsequences.IneffectiveChange]: 'Ineffective Change',
  [RiskConsequences.SlowRecovery]: 'Slow recovery',
  [RiskConsequences.ReducedAccessToStaffSkills]: 'Reduced access to staff / skills',
  [RiskConsequences.LossOfSuppliers]: 'Loss of suppliers',
  [RiskConsequences.EnvironmentalHarm]: 'Environmental harm',
  [RiskConsequences.SafetyFailure]: 'Safety failure',
  [RiskConsequences.SocialHarm]: 'Social harm',
  [RiskConsequences.MedicalHarm]: 'Medical harm',
  [RiskConsequences.NonCompliance]: 'Non-compliance',
  [RiskConsequences.PoorConductIntegrity]: 'Poor conduct / integrity',
  [RiskConsequences.DamagedRegulatorRelations]: 'Damaged regulator relations',
  [RiskConsequences.RegulatoryFines]: 'Regulatory fines',
  [RiskConsequences.LegalChallenge]: 'Legal challenge',
  [RiskConsequences.TheftOfMoney]: 'Theft of money',
  [RiskConsequences.UnplannedCosts]: 'Unplanned costs',
  [RiskConsequences.IncreasedCostsInefficiency]: 'Increased costs / inefficiency',
  [RiskConsequences.DamagedReputation]: 'Damaged reputation',
  [RiskConsequences.EmbarrassingReporting]: 'Embarrassing reporting',
  [RiskConsequences.DamagedInvestorRelations]: 'Damaged investor relations',
};

export const riskConsequencesOptions = [
  {
    label: 'Operations',
    options: [
      { label: riskConsequencesNames[RiskConsequences.ReducedGrowth], value: RiskConsequences.ReducedGrowth },
      { label: riskConsequencesNames[RiskConsequences.BusinessDisruption], value: RiskConsequences.BusinessDisruption },
      { label: riskConsequencesNames[RiskConsequences.IneffectiveChange], value: RiskConsequences.IneffectiveChange },
      { label: riskConsequencesNames[RiskConsequences.SlowRecovery], value: RiskConsequences.SlowRecovery },
      {
        label: riskConsequencesNames[RiskConsequences.ReducedAccessToStaffSkills],
        value: RiskConsequences.ReducedAccessToStaffSkills,
      },
      { label: riskConsequencesNames[RiskConsequences.LossOfSuppliers], value: RiskConsequences.LossOfSuppliers },
      { label: riskConsequencesNames[RiskConsequences.EnvironmentalHarm], value: RiskConsequences.EnvironmentalHarm },
      { label: riskConsequencesNames[RiskConsequences.SafetyFailure], value: RiskConsequences.SafetyFailure },
      { label: riskConsequencesNames[RiskConsequences.SocialHarm], value: RiskConsequences.SocialHarm },
      { label: riskConsequencesNames[RiskConsequences.MedicalHarm], value: RiskConsequences.MedicalHarm },
    ],
  },
  {
    label: 'Compliance',
    options: [
      { label: riskConsequencesNames[RiskConsequences.NonCompliance], value: RiskConsequences.NonCompliance },
      {
        label: riskConsequencesNames[RiskConsequences.PoorConductIntegrity],
        value: RiskConsequences.PoorConductIntegrity,
      },
      {
        label: riskConsequencesNames[RiskConsequences.DamagedRegulatorRelations],
        value: RiskConsequences.DamagedRegulatorRelations,
      },
      { label: riskConsequencesNames[RiskConsequences.RegulatoryFines], value: RiskConsequences.RegulatoryFines },
      { label: riskConsequencesNames[RiskConsequences.LegalChallenge], value: RiskConsequences.LegalChallenge },
    ],
  },
  {
    label: 'Financial',
    options: [
      { label: riskConsequencesNames[RiskConsequences.TheftOfMoney], value: RiskConsequences.TheftOfMoney },
      { label: riskConsequencesNames[RiskConsequences.UnplannedCosts], value: RiskConsequences.UnplannedCosts },
      {
        label: riskConsequencesNames[RiskConsequences.IncreasedCostsInefficiency],
        value: RiskConsequences.IncreasedCostsInefficiency,
      },
    ],
  },
  {
    label: 'Strategic',
    options: [
      { label: riskConsequencesNames[RiskConsequences.DamagedReputation], value: RiskConsequences.DamagedReputation },
      {
        label: riskConsequencesNames[RiskConsequences.EmbarrassingReporting],
        value: RiskConsequences.EmbarrassingReporting,
      },
      {
        label: riskConsequencesNames[RiskConsequences.DamagedInvestorRelations],
        value: RiskConsequences.DamagedInvestorRelations,
      },
    ],
  },
];

export const incidentTypeNames = {
  [IncidentType.Controlled]: 'Controlled',
  [IncidentType.Uncontrolled]: 'Uncontrolled',
};

export const incidentTypeOptions = [
  { value: IncidentType.Controlled, label: incidentTypeNames[IncidentType.Controlled] },
  { value: IncidentType.Uncontrolled, label: incidentTypeNames[IncidentType.Uncontrolled] },
];

export const strategyOptions = [
  { label: Strategy.Treat, value: Strategy.Treat },
  { label: Strategy.Transfer, value: Strategy.Transfer },
  { label: Strategy.Tolerate, value: Strategy.Tolerate },
  { label: Strategy.Terminate, value: Strategy.Terminate },
];

export const controllStatusNames = {
  [ControllStatus.NotStarted]: 'Not started',
  [ControllStatus.InProgress]: 'In progress',
  [ControllStatus.Live]: 'Live',
  [ControllStatus.Abandoned]: 'Abandoned',
};

export const controllStatusOptions = [
  { label: controllStatusNames[ControllStatus.NotStarted], value: ControllStatus.NotStarted },
  { label: controllStatusNames[ControllStatus.InProgress], value: ControllStatus.InProgress },
  { label: controllStatusNames[ControllStatus.Live], value: ControllStatus.Live },
  { label: controllStatusNames[ControllStatus.Abandoned], value: ControllStatus.Abandoned },
];

export const PlanNames = {
  [Plan.Trial]: 'Trial',
  [Plan.Paid]: 'Paid',
};

export const WorkspaceTypeNames = {
  [WorkspaceType.Trial]: 'Trial',
  [WorkspaceType.Paid]: 'Paid',
};

export const UserRoleNames = {
  [UserRole.Admin]: 'Admin',
  [UserRole.Collaborator]: 'Collaborator',
};

export const planOptions = [
  { label: PlanNames[Plan.Trial], value: Plan.Trial },
  // { label: PlanNames[Plan.Paid], value: Plan.Paid },
];
export const workspaceTypeOptions = [
  { label: WorkspaceTypeNames[WorkspaceType.Trial], value: WorkspaceType.Trial },
  // { label: PlanNames[Plan.Paid], value: Plan.Paid },
];

export const restrictAccessOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const roleOptions = [
  { label: UserRoleNames[UserRole.Admin], value: UserRole.Admin },
  { label: UserRoleNames[UserRole.Collaborator], value: UserRole.Collaborator },
];

export const orgTreeItemTypeNames = {
  W: 'Workspace',
  O: 'Organisation',
};

export const sortOptions = [
  { label: 'Sort by Date Created', value: SortType.ByCreatedDate, dir: 'desc' },
  { label: 'Sort by Review Date', value: SortType.ByReviewDate, dir: 'asc' },
  { label: 'Sort by Expected Loss', value: SortType.ByExpectedLoss, dir: 'desc' },
  { label: 'Sort by Number of Scenarios', value: SortType.ByNumberOfScenarios, dir: 'desc' },
  { label: 'Sort by Alphabetical Order', value: SortType.ByName, dir: 'asc' },
  { label: 'Sort by Last Edited', value: SortType.UpdatedAt, dir: 'desc' },
];
